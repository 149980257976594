import { PageRequest, Pager } from 'src/types/base';
import {
  IClusterGroupRiskOverviewVO,
  IClusterOverviewVO,
  IInspectRuleVO,
  IInspectUpdateRuleRequest,
  IRiskVO,
  IEventVO,
} from 'src/types/inspect';
import { get, postForm, patch, post } from 'src/utils/axios';

/**
 * 根据不同类型获取巡检规则列表
 * @param groupId 集群组id
 * @param page 第几页
 * @param size 每页大小
 * @param type 巡检规则所属类别
 */
export const pageInspect = (groupId: string, params: { type: string } & PageRequest) =>
  get<Pager<IInspectRuleVO>>(`/groups/${groupId}/inspections`, {
    pageSize: params.pageSize,
    pageNum: params.pageNum,
    type: params.type,
  });

/**
 * 更新巡检规则
 * @param rule 待更新巡检规则信息
 * @returns
 */
export const updateInspect = (
  groupId: string,
  inspectId: string,
  rule: IInspectUpdateRuleRequest
) =>
  patch<IInspectRuleVO>(`/groups/${groupId}/inspections/${inspectId}`, {
    enable: rule.enable,
    period: rule.checkPeriod,
  });

/**
 * 更新表达式
 * @param groupId 集群组id
 * @param inspectId 巡检规则id
 * @param expressionId 表达式内输入id
 * @param expressionValue 表达式值
 * @returns
 */
export const updateExpression = (
  groupId: string,
  inspectId: string,
  expressionId: string,
  expressionValue: any
) =>
  patch(`/groups/${groupId}/inspections/${inspectId}/expression/${expressionId}`, {
    value: expressionValue,
  });

export const searchClusterOverviews = (
  groupId: string,
  params: PageRequest & {
    keywords: string;
    riskLevel?: string;
  }
): Promise<Pager<IClusterOverviewVO>> =>
  get<Pager<IClusterOverviewVO>>(`/risk/${groupId}/cluster-overviews`, params);

/**
 * 通过集群id获取集群最新风险信息
 * @param clusterId 集群id
 * @returns
 */
export const getClusterOverview = (groupId: string, clusterId: string) =>
  get<IClusterOverviewVO>(`/risk/${groupId}/cluster-overviews/${clusterId}`);

export const searchRisks = (
  params: {
    clusterId: string;
    groupId: string;
    search?: string;
    resourceTypes?: string[];
    riskTypes?: string[];
    levels?: string[];
    statusTypes: string[];
  } & PageRequest
) =>
  post<Pager<IRiskVO>>(
    `/risk/${params.groupId}/cluster/${params.clusterId}/:filterPageQuery`,
    params
  );

export const finishRisk = (groupId: string, clusterId: string, riskId: number) =>
  postForm<IRiskVO>(`/risk/${groupId}/cluster/${clusterId}/:finishProcessedRisk`, { riskId });

export const getGroupRiskOverview = (groupId: string) =>
  get<IClusterGroupRiskOverviewVO>(`/risk/${groupId}/cluster-group-overview`);

export const getRiskRelatedEvent = (
  params: { clusterId: string; groupId: string; riskId: number } & PageRequest
): Promise<Pager<IEventVO>> =>
  get<Pager<IEventVO>>(`/risk/${params.groupId}/cluster/${params.clusterId}/relatedEvents`, params);
