import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import { CardContent, IconButton, Link, Typography } from '@mui/material';
import { useLicenseStatusContext } from 'src/contexts/license-status/use-license-status-context';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { useLicenseForm } from 'src/hooks/use-system-manage';
import { postActivate } from 'src/api/system-api';
import Iconify from '../../../../components/iconify';
// hooks

export default function ExpireActivation() {
  const { t } = useTranslation();
  const { isExpired, installID, mutation } = useLicenseStatusContext();
  const { copy } = useCopyToClipboard();
  const methods = useLicenseForm();
  const [open, setOpen] = useState(false);
  const handleUpdate = async (data: any) => {
    try {
      await postActivate({
        licenseCode: data.secretKey,
      });
      await mutation();
      setOpen(false);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const linkToHome = (e: any) => {
    window.open('https://home.automq.com', '_blank');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isExpired}
      sx={{ '& .MuiDialog-paper': { minWidth: 480 } }}
    >
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" justifyContent="start" alignItems="center">
          <IconButton>
            <Iconify color="error.main" icon="material-symbols:error" width={24} />
          </IconButton>
          {t('system.service_expiration')}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Card sx={{ boxShadow: 'none', borderRadius: 0 }}>
              <Stack sx={{ mb: 2 }}>
                <Typography variant="body1" color="text.secondary">
                  {t('system.service_expiration_tips')}
                </Typography>
              </Stack>
              <CardContent sx={{ px: 0, py: 0 }}>
                <Typography variant="subtitle2" color="text.primary" sx={{ mb: 1 }}>
                  {t('system.copy_installation_id_tips')}
                  <Link
                    color="primary"
                    sx={{
                      display: 'inline-flex',
                    }}
                    onClick={linkToHome}
                  >
                    {t('system.create_product_key')}
                    <Iconify icon="mdi:copy" />
                  </Link>
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="warning.lighter"
                  onMouseEnter={() => {
                    setOpen(true);
                  }}
                  onMouseLeave={() => {
                    setOpen(false);
                  }}
                  onClick={() => {
                    copy(installID);
                  }}
                  sx={{
                    border: '1px solid rgba(122, 65, 0, 0.10)',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    position: 'relative',
                    px: 2,
                    py: 1,
                    mb: 1,
                  }}
                >
                  <Typography color="text.primary" variant="caption">
                    {installID}
                  </Typography>
                  {open && (
                    <Stack
                      color="text.primary"
                      bgcolor="common.white"
                      sx={{
                        boxShadow:
                          '-20px 20px 40px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px 0px rgba(145, 158, 171, 0.24)',
                        border: '1px solid rgba(122, 65, 0, 0.10)',
                        position: 'absolute',
                        right: 0,
                        top: '-58px',
                        textAlign: 'center',
                        fontWeight: '400',
                        px: 1.5,
                        py: 1.5,
                      }}
                    >
                      <Stack
                        sx={{
                          position: 'relative',
                        }}
                      >
                        {t('click_and_copy')}
                        <Iconify
                          color="common.white"
                          sx={{
                            bottom: '-22px',
                            left: '22px',
                            position: 'absolute',
                          }}
                          icon="teenyicons:down-solid"
                        />
                      </Stack>
                    </Stack>
                  )}
                </Stack>
                <Typography variant="caption" color="text.secondary">
                  {t('system.activation_tips')}
                </Typography>
                <Stack>
                  <Typography variant="subtitle2" color="text.primary" sx={{ mt: 2.5, mb: 1 }}>
                    {t('system.activation_for_key')}
                  </Typography>
                  <FormProvider methods={methods}>
                    <Stack gap={2}>
                      <RHFTextField
                        multiline
                        margin="dense"
                        name="secretKey"
                        rows={4}
                        label=""
                        placeholder={t('system.please_enter_product_key')}
                        helperText={t('system.product_key_tips')}
                      />
                    </Stack>
                  </FormProvider>
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          sx={{ height: '36px' }}
          variant="outlined"
          onClick={methods.handleSubmit(handleUpdate)}
        >
          {t('oK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
