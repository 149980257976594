import { del, get, patch, post, postForm } from 'src/utils/axios';
import {
  IClusterVO,
  ICreateClusterGroupRequest,
  ICreateClusterRequest,
  IUpdateClusterRequest,
} from 'src/types/cluster';
import { PageRequest, Pager } from 'src/types/base';
import { IClusterGroup, IClusterGroupWithDetail } from 'src/types/cluster-group';

export const getClusterGroups = () =>
  get<IClusterGroup[]>(
    `/groups`,
    {},
    {
      headers: {},
    }
  );
/**
 * 分页获取集群组下的集群列表
 * @param groupId 集群组 id
 * @param pageSize 分页大小
 * @param pageNum 分页页码，从 1 开始
 * @returns
 */
export const pageClusters = (params: { groupId: string } & PageRequest) =>
  get<Pager<IClusterVO>>(
    `/groups/${params.groupId}/managed-clusters`,
    {
      pageSize: params.pageSize,
      pageNum: params.pageNum,
    },
    {
      headers: {
        HANDLE_RESP_CODE: ['CLUSTER_GROUP_NOT_EXIST'],
      },
    }
  );

/**
 * 批量删除集群
 * @param clusterIds 集群 id 列表
 * @returns
 */
export const deleteClusters = (groupId: string, clusterId: string) =>
  del<void>(`/groups/${groupId}/clusters/${clusterId}`);

/**
 * 获取集群组下的集群列表的实时信息
 * @param groupId 集群组 id
 * @returns
 */
export const listClustersDetailRealTime = async (groupId: string) => {
  if (!groupId || groupId.trim() === '') {
    return [];
  }
  const groupRealTimeInfo = await get<IClusterGroup>(`/groups/${groupId}`);
  return groupRealTimeInfo.clusters;
};

/**
 * 接入集群
 * @param cluster 待接入的集群
 * @returns
 */
export const integrateCluster = (groupId: string, cluster: ICreateClusterRequest) =>
  post<IClusterVO>(`/groups/${groupId}/clusters`, cluster);
export const integrateClusters = (groupId: string, clusters: ICreateClusterRequest[]) =>
  post<IClusterVO>(`/groups/${groupId}/clusters/:batchIntegrate`, clusters);

/**
 * 获取集群信息
 * @param clusterId 集群 id
 * @returns
 */
export const getClusterInfo = (groupId: string, clusterId: string) =>
  get<IClusterVO>(`/groups/${groupId}/clusters/${clusterId}`);

/**
 * 更新集群信息
 * @param cluster 待更新的集群
 * @returns
 */
export const updateCluster = (groupId: string, cluster: IUpdateClusterRequest) =>
  patch<IClusterVO>(`groups/${groupId}/clusters/${cluster.clusterId}`, cluster);

export const clusterEnableSLI = (groupId: string, clusterId: string, topic?: string) =>
  postForm<boolean>(`sli/${groupId}/cluster/${clusterId}/:enableSLI`, { topic });
export const clusterDisableSLI = (groupId: string, clusterId: string) =>
  post<boolean>(`sli/${groupId}/cluster/${clusterId}/:disableSLI`, {});

/**
 * 获取集群列表
 * @param groupId 集群组 id
 * @returns
 */
export const getAllClusters = async (groupId: string) => {
  const data = await get<Pager<IClusterVO>>(`/groups/${groupId}/managed-clusters`, {
    // 给一个比较大的分页，获取到所有数据
    pageSize: 2 ** 12,
    pageNum: 1,
  });
  return data.list;
};

// cluster group 相关 api
export const addClusterGroup = (group: ICreateClusterGroupRequest) =>
  post<IClusterGroup>('/groups', group);

export const deleteClusterGroup = (groupId: string) => del(`/groups/${groupId}`);

/**
 * 获取集群信息
 * @param clusterId 集群 id
 * @returns
 */
export const getClusterGroupInfo = (groupId: string) =>
  get<IClusterGroupWithDetail>(`/groups/${groupId}`);

/**
 * 获取集群信息
 * @param clusterId 集群 id
 * @returns
 */
export const getClusterGroupBasicInfo = (groupId: string) =>
  get<IClusterGroupWithDetail>(`/groups/${groupId}/basic-info`);

interface ClusterGroupUpdate {
  namesrvList: string[];
  name: string;
  enableACL: boolean;
  accessKey: string;
  secretKey: string;
  remark: string;
  sshUsername: string;
  sshPassword: string;
}
/**
 * 获取集群信息
 * @param clusterId 集群 id
 * @returns
 */
export const updateClusterGroup = (groupId: string, updateData: Partial<ClusterGroupUpdate>) =>
  patch<IClusterGroupWithDetail>(`/groups/${groupId}`, updateData);
