import Button from '@mui/material/Button';

import Alert from '@mui/material/Alert';
import { useLicenseStatusContext } from 'src/contexts/license-status/use-license-status-context';
import { useLocales } from '../../../locales';

export default function ActivateAlert() {
  const { t } = useLocales();
  const { willExpired, isAvailable, license, setActivateDialogOpen } = useLicenseStatusContext();

  const openActivateDialog = (e: any) => {
    setActivateDialogOpen(true);
  };
  if (!willExpired) {
    return <span />;
  }
  // @ts-ignore
  return (
    <Alert
      severity="warning"
      sx={{ mt: 2.5 }}
      action={
        <>
          <Button variant="contained" color="warning" sx={{ mr: 1 }} onClick={openActivateDialog}>
            {t('handle')}
          </Button>
          {/* <Button
            variant="outlined"
            color="warning"
            startIcon={<Iconify icon="mingcute:add-line"/>}
            onClick={() => {
              setIsAlertShow(false);
              isAlertClosed.current = true;
            }}
          >
            {t('notProcess')}
          </Button> */}
        </>
      }
    >
      {isAvailable
        ? t('system.activation_recommended', { day: license?.remainingDays ?? 0 })
        : t('system.unregistered_usage_recommended', { day: license?.remainingDays ?? 0 })}
    </Alert>
  );
}
