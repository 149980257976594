import { createContext, useMemo, useState } from 'react';

export type ConfirmCallback = () => void;
export interface ConfirmContextType {
  open: boolean;
  title: string;
  content: string;
  variant: 'error' | 'info';
  onConfirm: ConfirmCallback;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  setOnConfirm: React.Dispatch<React.SetStateAction<ConfirmCallback>>;
  setVariant: React.Dispatch<React.SetStateAction<'error' | 'info'>>;
}

export const ConfirmContext = createContext<ConfirmContextType>({
  open: false,
  title: '',
  content: '',
  variant: 'info',
  onConfirm: () => {},
  setOpen: () => {},
  setTitle: () => {},
  setContent: () => {},
  setOnConfirm: () => {},
  setVariant: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function ConfirmDialogProvider({ children }: Props) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [variant, setVariant] = useState<'error' | 'info'>('info');
  const [onConfirm, setOnConfirm] = useState<ConfirmCallback>(() => () => {});

  const value = useMemo<ConfirmContextType>(
    () => ({
      open,
      title,
      content,
      variant,
      setVariant,
      onConfirm,
      setOpen,
      setTitle,
      setContent,
      setOnConfirm,
    }),
    [open, title, content, variant, onConfirm]
  );

  return <ConfirmContext.Provider value={value}>{children}</ConfirmContext.Provider>;
}
