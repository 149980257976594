import { useCallback } from 'react';

// @mui
import MenuItem from '@mui/material/MenuItem';
import { Button, Stack, Typography } from '@mui/material';

// locales
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify/iconify';

// ----------------------------------------------------------------------

export default function DevToolPopover() {
  const popover = usePopover();

  const handleChangeLang = useCallback(
    (value: string) => {
      localStorage.setItem('showTextKey', value);
      popover.onClose();
      window.location.reload();
    },
    [popover]
  );

  return (
    <>
      <Button onClick={popover.onOpen}>
        <Stack direction="row" gap={1} alignItems="center">
          <Typography fontWeight={600}>开发工具</Typography>
          <Iconify icon="mingcute:down-fill" />
        </Stack>
      </Button>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        <MenuItem
          key="open"
          selected={localStorage.getItem('showTextKey') === 'open'}
          onClick={() => handleChangeLang('open')}
        >
          ShowTextKey
        </MenuItem>
        <MenuItem
          key="close"
          selected={localStorage.getItem('showTextKey') === 'close'}
          onClick={() => handleChangeLang('close')}
        >
          HiddenTextKey
        </MenuItem>
      </CustomPopover>
    </>
  );
}
