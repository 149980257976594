import { createContext, PropsWithChildren } from 'react';
import { useLicenseStatus } from 'src/hooks/use-system-manage';

export const LicenseStatusContext = createContext<ReturnType<typeof useLicenseStatus> | undefined>(
  undefined
);

export function LicenseStatusProvider({ children }: PropsWithChildren) {
  const values = useLicenseStatus();
  return <LicenseStatusContext.Provider value={values}>{children}</LicenseStatusContext.Provider>;
}
