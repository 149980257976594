// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

// routes
import Router from 'src/routes/sections';
// theme
import ThemeProvider from 'src/theme';
// hooks
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
// components
import ProgressBar from 'src/components/progress-bar';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { SettingsProvider, SettingsDrawer } from 'src/components/settings';
import SnackbarProvider from 'src/components/snackbar/snackbar-provider';
// auth
import { AuthProvider } from 'src/auth/context/jwt';
import TempCIdGuard from './routes/guard/temp-custom-id-guard';
import { enableI18nViewer } from './i18n-viewer';

export default function App() {
  useScrollToTop();
  // 获取当前环境
  if (process.env.NODE_ENV === 'development') {
    enableI18nViewer();
  }
  return (
    <AuthProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <ThemeProvider>
          <TempCIdGuard>
            <MotionLazy>
              <SnackbarProvider>
                <SettingsDrawer />
                <ProgressBar />
                <Router />
              </SnackbarProvider>
            </MotionLazy>
          </TempCIdGuard>
        </ThemeProvider>
      </SettingsProvider>
    </AuthProvider>
  );
}
