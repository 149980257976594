import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IBroker } from 'src/types/broker';
import { convertBrokerId } from 'src/converter/cluster-converter';
import { useLocales } from 'src/locales';

type Props = {
  brokers: IBroker[];
};

export default function BrokerTableView({ brokers }: Props) {
  const { t } = useLocales();
  const columns: GridColDef[] = [
    {
      field: 'brokerName',
      headerName: 'BrokerName',
      align: 'center',
      headerAlign: 'center',
      width: 180,
    },
    {
      field: 'brokerId',
      headerName: 'brokerId',
      align: 'center',
      headerAlign: 'center',
      width: 120,
      renderCell: (params) => <>{`${params.value}(${convertBrokerId(params.value, t)})`}</>,
    },
    {
      field: 'addr',
      headerName: t('cluster_manage.integrate.IP_port'),
      align: 'center',
      headerAlign: 'center',
      width: 160,
    },
    // {
    //   field: 'startTime',
    //   headerName: t('brokerStartTime'),
    //   align: 'center',
    //   headerAlign: 'center',
    //   width: 216,
    // },
  ];

  return (
    <div style={{ width: 676 }}>
      <DataGrid
        autoHeight
        rowSelection={false}
        hideFooter
        // TODO: DataGrid最多只能放100条数据，超出可能换成普通Table组件
        pageSizeOptions={[100]}
        getRowId={(row) =>
          JSON.stringify({
            brokerId: row.brokerId,
            brokerName: row.brokerName,
          })
        }
        rows={brokers}
        columns={columns}
      />
    </div>
  );
}
