// @mui
import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import RiskPanelView from 'src/sections/system-inspect/risk/risk-panel-view';
import { useClusterGroupContext } from '../../../contexts/cluster-group/use-cluster-group-context';
import NoClusterView from '../../../sections/cluster-manage/cluster/components/cluster-empty';

export default function Page() {
  const { t } = useLocales();
  const { clustersUnderGroup } = useClusterGroupContext();
  return (
    <>
      <Helmet>
        <title>{t('risk_deal_manage.risk_deal_panel')}</title>
      </Helmet>
      {!clustersUnderGroup || clustersUnderGroup.length === 0 ? (
        <NoClusterView />
      ) : (
        <RiskPanelView />
      )}
    </>
  );
}
