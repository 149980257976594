import { useEffect, useState, createContext, useCallback, useMemo, useRef } from 'react';
import { useParams, useRouter } from 'src/routes/hooks';

import axios, { endpoints } from 'src/utils/axios';
import { matchPath, useLocation } from 'react-router';

import { replaceDynamicParams, useRouteInfo } from 'src/hooks/use-paths';
import { IClusterVO } from '../../types/cluster';
import { pageClusters } from '../../api/cluster-api';

type ClusterGroupContextType = {
  clusterGroups: any[];
  clustersUnderGroup: IClusterVO[];
  selectedClusterGroupId: string | undefined;
  setSelectedClusterGroupId: React.Dispatch<React.SetStateAction<string | undefined>>;
  initialize: () => void;
  getGroupName: (groupId: string) => string;
  setNavigatePrevent: () => void;
};

export const ClusterGroupContext = createContext<ClusterGroupContextType | undefined>(undefined);
type Props = {
  children: React.ReactNode;
};

interface ClusterGroup {
  groupId: string;
  name: string;
}

export function ClusterGroupProvider({ children }: Props) {
  const { groupId } = useParams();
  const [clusterGroups, setClusterGroups] = useState<ClusterGroup[]>([]);
  const [clustersUnderGroup, setClustersUnderGroup] = useState<IClusterVO[]>([]);

  const [selectedClusterGroupId, setSelectedClusterGroupId] = useState(groupId);
  const router = useRouter();
  const preventRef = useRef(false);
  const location = useLocation();
  const { route, moduleIndexUrl } = useRouteInfo();
  // const initRouteInfo = matchPath({ path: route.path, end: true }, location.pathname);
  // 跳过下一次自动跳转
  const setNavigatePrevent = useCallback(() => {
    preventRef.current = true;
  }, []);
  const initialize = useCallback(async () => {
    let res;
    try {
      res = await axios.get(endpoints.clusterGroup.list);
    } catch (e) {
      console.log(e);
      return;
    }
    const clusterList: ClusterGroup[] = res.data;
    setClusterGroups(clusterList);
    if (clusterList.length === 0) {
      return;
    }
    let currentGroupId = selectedClusterGroupId;
    if (!clusterList.some((cv) => cv.groupId === currentGroupId)) {
      currentGroupId = undefined;
    }
    if (!currentGroupId) {
      if (groupId && clusterList.some((cv) => cv.groupId === groupId)) {
        currentGroupId = groupId;
      } else {
        currentGroupId = clusterList[0]?.groupId;
      }
      setSelectedClusterGroupId(currentGroupId);
    }
    if (!currentGroupId) {
      return;
    }
    // get clusters info under group
    const data = await pageClusters({
      groupId: currentGroupId!,
      pageNum: 1,
      pageSize: 10,
    });
    setClustersUnderGroup(data.list);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClusterGroupId]);

  const getGroupName = useCallback(
    (id: string) => clusterGroups.filter((item) => item.groupId === id)[0]?.name,
    [clusterGroups]
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (selectedClusterGroupId) {
      if (preventRef.current) {
        preventRef.current = false;
        return;
      }
      let redirectUrl;
      if (route.path.startsWith('/manangement')) {
        redirectUrl = undefined;
      } else {
        redirectUrl = route.path ?? location.pathname;
      }
      // 首次进入页面，不跳转
      if (redirectUrl) {
        // 从初始链接中获取参数
        const initRouteInfo = matchPath({ path: route.path, end: true }, location.pathname);
        const params = initRouteInfo?.params ?? {};
        // 如果进入页面带的groupId和当前选中groupId不同，切换到模块首页
        if (params.groupId && params.groupId !== selectedClusterGroupId) {
          redirectUrl = moduleIndexUrl!;
        }
        Object.assign(params, { groupId: selectedClusterGroupId });
        if (redirectUrl.includes(':')) {
          redirectUrl = replaceDynamicParams(redirectUrl, params);
        }
        router.push(redirectUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClusterGroupId]);

  const memoizedValue = useMemo(
    () => ({
      clusterGroups,
      selectedClusterGroupId,
      setSelectedClusterGroupId,
      initialize,
      getGroupName,
      clustersUnderGroup,
      setNavigatePrevent,
    }),
    [
      clusterGroups,
      selectedClusterGroupId,
      initialize,
      getGroupName,
      clustersUnderGroup,
      setNavigatePrevent,
    ]
  );

  return (
    <ClusterGroupContext.Provider value={memoizedValue}>{children}</ClusterGroupContext.Provider>
  );
}
