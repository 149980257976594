import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Iconify from 'src/components/iconify';

interface Props {
  open: boolean;
  onClose: () => void;
  onEdit: (data: any) => Promise<void>;
}

export function ClusterDelDialog({ open, onClose, onEdit }: Props) {
  const { t } = useTranslation();
  // const LoginSchema = Yup.object().shape({
  //   name: Yup.string().required(t('cluster.integrate.topic_for_sli_required')),
  //   TopicName: Yup.string().required(t('cluster.integrate.topic_for_sli_required')),
  // });
  // const defaultValues = {
  //   name: '',
  //   TopicName: '',
  // };
  // const methods = useForm({
  //   resolver: yupResolver(LoginSchema),
  //   defaultValues,
  // });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      sx={{ '& .MuiDialog-paper': { width: 480 } }}
    >
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" justifyContent="start" alignItems="center">
          <IconButton sx={{ px: 0 }}>
            <Iconify color="text.primary" width="28px" icon="prime:trash" />
          </IconButton>
          {t('del')} 日常集群(dailyClusterooooiioo) {t('inspection_manage.white_list')}
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ px: 3, py: 2, borderRadius: 0 }}>
        <Typography variant="body1" color="text.secondary">
          {t('delete.confirm')}
          {t('del')} 日常集群(dailyClusterooooiioo) {t('inspection_manage.white_list')}
          {t('delete.unable_cancel_warning')}
        </Typography>
        <TextField sx={{ width: '100%', mt: 2.5 }} placeholder="输入 确认 以同意" />
      </DialogContent>
      <DialogActions>
        <Button sx={{ height: '36px' }} variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button color="primary" sx={{ height: '36px' }} variant="outlined">
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
