// @mui
import { useLocales } from 'src/locales';

import { CardProps } from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { riskLevels } from 'src/constants/risk';
import riskbg from 'src/images/riskbg.png';
// theme
// utils
// theme

// ----------------------------------------------------------------------

interface Props extends CardProps {
  groupInfo: {
    total: number;
    serious: number;
    urgent: number;
    healthy: number;
    p0count: number;
    p1count: number;
    p2count: number;
  };
}

export default function AnalyticsGroupSummary({ sx, groupInfo }: Props) {
  const { t } = useLocales();
  const counts = [groupInfo.p0count, groupInfo.p1count, groupInfo.p2count, groupInfo.healthy];
  const status = riskLevels.map((item, i) => ({
    name: t(item.label),
    color: item.color,
    value: counts[i],
  }));
  const statusLabel = {
    width: '8px',
    height: '8px',
    borderRadius: '100%',
  };
  return (
    <Stack
      bgcolor="primary.light"
      color="common.white"
      sx={{
        pt: 3,
        pb: 3,
        px: 0,
        borderRadius: 2,
        width: '100%',
        backgroundImage: `url(${riskbg})`,
        backgroundSize: '100% 100%',
        ...sx,
      }}
    >
      <Stack direction="row" alignItems="baseline" spacing={4.5} justifyContent="center">
        <Stack direction="column" alignItems="flex-start" justifyContent="center" spacing={0.25}>
          <Typography variant="h3" lineHeight="48px">
            {groupInfo.total}
            <Typography ml={1} component="span" variant="subtitle1">
              {t('indivual')}
            </Typography>
          </Typography>
          <Typography color="primary.lighter" variant="body2" fontSize={13}>
            {t('risk_deal_panel.cluster_count')}
          </Typography>
          <Stack bgcolor="common.white" sx={{ ...statusLabel }} />
        </Stack>
        <Stack direction="row" spacing={4.5} alignItems="center" justifyContent="center">
          {status.map((item, index) => (
            <Stack
              key={item.name}
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={0.25}
            >
              <Typography variant="h3" lineHeight="48px">
                {item.value}
              </Typography>
              <Typography variant="body2" fontSize={13}>
                {item.name}
              </Typography>
              <Stack sx={{ background: item.color, ...statusLabel }} />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
}
