import { Typography } from '@mui/material';
import { CardProps } from '@mui/material/Card';
import { Stack } from '@mui/system';

export interface Props extends CardProps {
  title: string;
  data: number | string;
  unit: string;
}

export default function AnalyticsData({ title, data, unit, sx }: Props) {
  const s = { ...sx };
  return (
    <Stack sx={{ ...s, height: '100%' }} direction="column" justifyContent="space-between">
      <Typography variant="body2" color="text.secondary" noWrap>
        {title}
      </Typography>
      <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
        <Stack direction="row" alignItems="center" flexWrap="wrap">
          <Typography variant="h6" color="text.primary">
            {data}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }} justifyContent="center">
            {unit}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
