import { useFormContext, Controller } from 'react-hook-form';
// @mui
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FORM_CONFIG } from 'src/config-global';

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
  name: string;
  decimal?: boolean;
  disableNegative?: boolean;
};

export default function RHFTextField({
  name,
  helperText,
  type,
  decimal = true,
  disableNegative = false,
  ...other
}: Props) {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          inputProps={{
            onWheel: (e: any) => e.target.blur(),
          }}
          size={FORM_CONFIG.defaultSize}
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            if (type === 'number') {
              field.onChange(Number(event.target.value));
              setValue(name, Number(event.target.value), { shouldValidate: true });
            } else {
              setValue(name, event.target.value, { shouldValidate: true });
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
