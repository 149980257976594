// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 64,
  H_DESKTOP_OFFSET: 64 - 16,
  SPACE: 20,
};

export const NAV = {
  W_VERTICAL: 240,
  W_MINI: 88,
};
