// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import qrcode from 'src/images/qrcode.jpeg';

// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';

import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useLicenseStatusContext } from 'src/contexts/license-status/use-license-status-context';
import { Wrapper } from 'src/components/wrapper';
import { useLocales } from 'src/locales';
import { usePalette } from 'src/hooks/use-palette';
import { Dialog, DialogTitle, DialogContent, DialogActions, Link } from '@mui/material';
import React from 'react';
import { CloseIcon } from 'yet-another-react-lightbox';
import CopyButton from 'src/components/copy-button';
import { RocketLaunch } from '@mui/icons-material';
import { HEADER } from '../config-layout';
import { AccountPopover } from '../_common';

import DevToolPopover from '../_common/dev-tool-popover';
import ActivationIndex from '../../sections/system/components/activation';
import Iconify from '../../components/iconify';
import UpdateActivation from '../../sections/system/components/activation/update';
import CustomPopover, { usePopover } from '../../components/custom-popover';

type Props = {
  onOpenNav?: VoidFunction;
};

export default function Header({ onOpenNav }: Props) {
  const theme = useTheme();

  const settings = useSettingsContext();
  const { isUnactivate, activateDialogOpen, setActivateDialogOpen } = useLicenseStatusContext();
  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset;
  const { t } = useTranslation();
  const locale = useLocales();

  const toHelp = () => {
    if (locale.currentLang.value === 'en') {
      window.open(
        'https://docs.automq.com/docs/automq-copilot/VdDEwGLhNiGqLlkG4tZcQWzEnJA',
        '_blank'
      );
    } else {
      window.open(
        'https://docs.automq.com/zh/docs/automq-copilot/VdDEwGLhNiGqLlkG4tZcQWzEnJA',
        '_blank'
      );
    }
  };

  const handleActivateOpen = () => {
    setActivateDialogOpen(true);
  };
  const popover = usePopover();
  const { paletteColor } = usePalette();

  const [installationOpen, setInstallationOpen] = React.useState(false);

  const closeInstallation = () => {
    setInstallationOpen(false);
  };

  const openInstallation = () => {
    setInstallationOpen(true);
  };

  const copilotInstallCommand = process.env.REACT_APP_INSTALLATION_SCRIPT || '';

  const renderContent = (
    <>
      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}
      <Logo sx={{ mx: 'auto', my: 2 }} />
      <Typography variant="h6" sx={{ ml: 1 }} color="common.white">
      RocketMQ Copilot 
      </Typography>
      {process.env.REACT_APP_ENV === 'dev' && <DevToolPopover />}
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <Stack
          flexGrow={1}
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ ml: 2 }}
        >
          <Wrapper show={isUnactivate}>
            <Button
              variant="contained"
              sx={{
                borderRadius: '15px',
                background: paletteColor.primary.dark,
                fontSize: '13px',
                px: 2,
                py: 0.5,
                mx: 2,
              }}
              aria-haspopup="true"
              aria-controls="ringtone-menu"
              onClick={handleActivateOpen}
              endIcon={<Iconify sx={{ verticalAlign: 'middle' }} icon="majesticons:open-line" />}
            >
              {isUnactivate
                ? t('system.unactivate_subscription_plan')
                : t('FreeTrialSubscriptionPlanToUpdate')}
            </Button>
          </Wrapper>
          <ActivationIndex />
          <UpdateActivation open={activateDialogOpen} setOpen={setActivateDialogOpen} />
          {process.env.REACT_APP_PLAYGROUND === 'true' && (
            <Button onClick={openInstallation}>
              <Typography
                variant="body2"
                color="primary.lighter"
                noWrap
                sx={{
                  fontWeight: 'bolder',
                  color: 'white',
                }}
              >
                {t('one_click_installation')}
              </Typography>
              <RocketLaunch
                sx={{
                  color: 'white',
                  ml: 1,
                }}
              />
            </Button>
          )}
          <Button onClick={popover.onOpen}>
            <Typography variant="body2" color="primary.lighter" noWrap>
              {t('technical_support')}
            </Typography>
          </Button>
          <Dialog
            open={installationOpen}
            onClose={closeInstallation}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t('installation_dialog.title')}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={closeInstallation}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (curTheme) => curTheme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Stack direction="column">
                <Stack direction="row">
                  <Typography variant="body2" color="black">
                    {t('installation_dialog.step1')}
                  </Typography>
                  &nbsp;
                  <Link
                    href={process.env.REACT_APP_INSTALLATION_DOC || '#'}
                    sx={{ fontSize: 14 }}
                    target="_blank"
                  >
                    {t('installation_dialog.document_tutorial')}
                  </Link>
                </Stack>
                <Box
                  sx={{
                    mt: 1,
                  }}
                >
                  <Typography variant="caption" color="black">
                    {t('installation_dialog.cmd_hint')}
                  </Typography>
                </Box>
                <Stack
                  bgcolor="grey.100"
                  sx={{ borderRadius: '8px', px: 1.5, py: 2, mt: 0.5 }}
                  direction="row"
                  justifyContent="start"
                  alignItems="center"
                >
                  <Typography flex={1} variant="body2">
                    {copilotInstallCommand}
                  </Typography>
                  <CopyButton content={copilotInstallCommand} />
                </Stack>
                <Box sx={{ mt: 1 }}>
                  <Typography variant="caption">{t('installation_dialog.advice')}</Typography>
                </Box>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeInstallation} variant="outlined">
                {t('cancel')}
              </Button>
              <Button onClick={closeInstallation} variant="contained" color="primary" autoFocus>
                {t('oK')}
              </Button>
            </DialogActions>
          </Dialog>
          <CustomPopover sx={{ p: 2.5 }} open={popover.open} onClose={popover.onClose}>
            <Box
              component="img"
              alt="empty content"
              src={qrcode}
              sx={{
                width: 1,
                maxWidth: 280,
                mb: 1,
              }}
            />
            <Typography variant="body1" textAlign="center">
              {t('scan_to_join_in_community')}
            </Typography>
          </CustomPopover>
          <Button onClick={toHelp}>
            <Typography variant="body2" color="primary.lighter" noWrap>
              {t('help_document')}
              <Iconify sx={{ verticalAlign: 'middle', ml: 0.5 }} icon="majesticons:open-line" />
            </Typography>
          </Button>
          <AccountPopover />
        </Stack>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        zIndex: theme.zIndex.appBar + 1,
        borderBottom: `solid 1px ${theme.palette.divider}`,
        ...bgBlur({
          color: paletteColor.primary.main,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          // width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP,
          }),
          ...(isNavMini &&
            {
              // width: `calc(100% - ${NAV.W_MINI + 1}px)`,
            }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
