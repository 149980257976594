/**
 * 当dev模式且localStorage中的showTextKey为open时，会在文本前后添加!!<[key的索引]>，用于查看i18n的key
 * 当前文件会把所有国际化的key平铺并排序，从而得到key的索引(将一串key转换为一个数字，方便查看)
 * 当鼠标悬停在文本上时，会将文本高亮显示，并将key复制到剪贴板中，同时文本的背景色会变为绿色，字体颜色变为红色
 * @description 用于查看i18n的key的工具
 */
import CN from 'src/locales/langs/cn.json';
import EN from 'src/locales/langs/en.json';
import { localStorageGetItem } from './utils/storage-available';
import { defaultLang } from './locales';

const keys: string[] = [];
export const keyMap = new Map();
// 将CN中的嵌套的对象转换为扁平的对象,并进行排序
function flatten(obj: any, prefix = '') {
  const temp: string[] = [];
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'object') {
      flatten(value, newKey);
    } else {
      temp.push(newKey);
    }
  });
  // 对keys通过字母排序
  temp.sort().forEach(
    (key) => {
      keys.push(key);
    }
  );
  // 将keys中的key和index存入map中
  keys.forEach((key, idx) => {
    keyMap.set(key, idx);
  });
}

// 根据当前语言环境，获取对应的国际化文件
const lang = localStorageGetItem('i18nextLng', defaultLang.value);
flatten(lang === 'en' ? EN : CN);

function copy(text: string) {
  navigator.clipboard.writeText(text);
}
function getTextNodes(root: any, nodes: any[] = []) {
  if (root.nodeType === Node.TEXT_NODE) {
    nodes.push(root);
  } else {
    // eslint-disable-next-line no-restricted-syntax
    for (const child of root.childNodes) {
      getTextNodes(child, nodes);
    }
  }
  return nodes;
}

const map = new Map();

function recoverStyle(event: any) {
  // 恢复文本的原始样式
  event.target.style.backgroundColor = event.target.getAttribute('backgroundColor');
  event.target.style.color = event.target.getAttribute('color');
  clearTimeout(event.target.getAttribute('timeoutId'));
}

// 定义鼠标悬停时触发的事件处理函数
function handleMouseOver(event: any) {
  recoverStyle(event);
  const { textContent } = event.target;
  console.log(event.target);
  console.log(map, textContent);
  if (!map.get(textContent)) {
    console.log('找不到key');
    return;
  }
  console.log(textContent);
  // 在鼠标悬停时触发的事件
  const originStyle = {
    backgroundColor: event.target.style.backgroundColor,
    fontSize: event.target.style.fontSize,
    zIndex: event.target.style.zIndex,
    color: event.target.style.color,
    timeoutId: `${setTimeout(() => {
      if (copy) {
        const key = map.get(textContent);
        copy(keys[Number(key)]);
        console.log('copy success')
        event.target.style.backgroundColor = 'green';
        event.target.style.color = 'red';
      }
    }, 1000)}`,
  } as Record<string, string>;
  Object.keys(originStyle).forEach((key: string) => {
    event.target.setAttribute(key, originStyle[key]);
  });
  // 高亮显示文本
  event.target.style.backgroundColor = 'blue';
  event.target.style.color = 'white';
}

// 添加鼠标悬停监听器
const addHoverListenerToTextNodes = (root: any) => {
  // 获取所有文本节点
  const textNodes = getTextNodes(root);
  // 添加鼠标悬停监听器
  textNodes
    .map((originalTextElement) => {
      const text = originalTextElement.textContent;
      const start = text.indexOf('<[');
      const end = text.indexOf(']>');
      // 判断text中是否存在多个<[]>，如果存在，则不处理
      if (text.indexOf('<[', start + 1) !== -1) {
        return null;
      }

      const p = originalTextElement.parentNode;
      if (start !== -1 && end !== -1) {
        const key = text.substring(start + 2, end);
        let newContent = text.substring(0, start) + text.substring(end + 2);
        let idx = 0;
        while (map.get(newContent)) {
          idx += 1;
          newContent = `${text.substring(0, start) + text.substring(end + 2)}#${idx}`;
        }
        map.set(newContent, key);
        // 创建注释节点
        const commentNode = document.createComment(keys[Number(key)]);
        // 在文本节点后面插入注释节点
        p.insertBefore(commentNode, originalTextElement.nextSibling);
        originalTextElement.textContent = newContent;
        p.removeEventListener('mouseover', handleMouseOver);
        p.removeEventListener('mouseleave', recoverStyle);
        p.addEventListener('mouseover', handleMouseOver);
        p.addEventListener('mouseleave', recoverStyle);
        return p;
      }
      if (map.get(p)) {
        return p;
      }
      return null;
    })
    .filter((node) => node !== null);
};

export const enableI18nViewer = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // 每三秒重新添加一次监听器，因为有些节点可能是动态添加的
  setInterval(() => {
    // 如果localStorage中的showTextKey不为open，则不处理
    if (localStorageGetItem('showTextKey', 'close') !== 'open') {
      return;
    }
    console.log('refresh text nodes and mount hover listener');
    const root = document.body;
    if (root) {
      addHoverListenerToTextNodes(root);
    }
  }, 3000);
};
