// @mui
import { Box, Avatar } from '@mui/material';

// locales
import { deepPurple } from '@mui/material/colors';

// ----------------------------------------------------------------------
export default function AvatarIndex() {
  return (
    <Box
      sx={{
        width: 1,
        maxWidth: 30,
        borderRadius: '100%',
      }}
    >
      <Avatar sx={{ width: 30, height: 30, bgcolor: deepPurple[500] }}>A</Avatar>
    </Box>
  );
}
