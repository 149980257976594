import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

type CopiedValue = string | null;

type CopyFn = (text: string) => Promise<boolean>;

type ReturnType = {
  copy: CopyFn;
  copiedText: CopiedValue;
};

async function copyToClipboard(str: string) {
  if (!navigator.clipboard) {
    const textarea = document.createElement('textarea');
    textarea.value = str;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    return;
  }
  await navigator.clipboard.writeText(str);
}
export function useCopyToClipboard(): ReturnType {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const copy: CopyFn = async (text) => {
    try {
      await copyToClipboard(text);
      setCopiedText(text);
      enqueueSnackbar(t('copySuccess'), {
        variant: 'success',
      });
      return true;
    } catch (error) {
      enqueueSnackbar(t('copyFailure'), {
        variant: 'error',
        autoHideDuration: null,
      });
      return false;
    }
  };

  // const copy: CopyFn = async (text) => {
  //   const tempButton = document.createElement('button');

  //   const clipboard = new ClipboardJS(tempButton, {
  //     text: () => text,
  //   });

  //   return new Promise<boolean>((resolve, reject) => {
  //     clipboard.on('success', (e) => {
  //       console.log('文本已成功复制到剪贴板：', e.text);
  //       clipboard.destroy();
  //       setCopiedText(text);
  //       enqueueSnackbar(t('copySuccess'), {
  //         variant: 'success',
  //       });

  //       // 手动删除临时元素
  //       document.body.removeChild(tempButton);

  //       resolve(true);
  //     });

  //     clipboard.on('error', (e) => {
  //       console.error('无法复制文本到剪贴板：', e.text);
  //       clipboard.destroy();
  //       enqueueSnackbar(t('copyFailure'), {
  //         variant: 'error',
  //       });

  //       // 手动删除临时元素
  //       document.body.removeChild(tempButton);

  //       resolve(false);
  //     });

  //     // 将临时按钮添加到 DOM 以触发复制操作
  //     document.body.appendChild(tempButton);
  //     tempButton.click();
  //   });
  // };

  return { copiedText, copy };
}
