import { Grid, Stack, SxProps, Theme, Typography, alpha } from '@mui/material';
import { IClusterOverviewVO } from 'src/types/inspect';
import Label from 'src/components/label';
import { useLocales } from 'src/locales';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useClusterGroupContext } from 'src/contexts/cluster-group/use-cluster-group-context';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactEcharts from 'echarts-for-react';
import { riskLevels } from 'src/constants/risk';
import { useCallback, useMemo } from 'react';
import { Wrapper } from 'src/components/wrapper';
import { usePalette } from 'src/hooks/use-palette';

export interface Props {
  data: IClusterOverviewVO;
  sx?: SxProps<Theme>;
  commonRadius?: SxProps<Theme>;
  active?: boolean;
}

export default function PanelClusterRow({
  data,
  sx = {},
  active = false,
  commonRadius = {
    borderRadius: 2,
  },
}: Props) {
  const router = useRouter();
  const { selectedClusterGroupId } = useClusterGroupContext();
  const gotoClusterRiskDetail = () => {
    router.push(paths.dashboard.systemInspect.clusterRisk(selectedClusterGroupId!, data.clusterId));
  };
  // 提示文本的颜色
  const { t } = useLocales();
  const levelItem = useMemo(() => riskLevels.find((cv) => cv.value === data.level), [data]);
  const isInspectEmpty = useMemo(() => !data.inspect.total, [data]);
  const { paletteColor } = usePalette();
  const getOption = useCallback(
    (total: number, passCount: number) => {
      const color = isInspectEmpty
        ? [
            { offset: 0, color: alpha(paletteColor.grey[500], 0.16) },
            { offset: 0.5, color: alpha(paletteColor.grey[500], 0.16) },
            { offset: 1, color: alpha(paletteColor.grey[500], 0.16) },
          ]
        : levelItem?.colorAttr;
      return {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: total,
            splitNumber: 12,
            color: [
              {
                type: 'linear',
                colorStops: color,
              },
            ],
            itemStyle: {
              // color,
              shadowColor: 'rgba(255,255,255,0)',
              shadowBlur: 10,
              shadowOffsetX: 2,
              shadowOffsetY: 2,
            },
            progress: {
              show: true,
              roundCap: true,
              width: 10,
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 10,
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            pointer: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            title: {
              show: false,
            },
            detail: {
              show: false,
            },
            data: isInspectEmpty
              ? undefined
              : [
                  {
                    value: passCount,
                  },
                ],
          },
        ],
      };
    },
    [isInspectEmpty, levelItem, paletteColor]
  );

  const masterNode = data.totalMaster;
  const slaveNode = data.totalSlave;

  // 原点样式
  const statusLabel = {
    width: '8px',
    height: '8px',
    borderRadius: '100%',
  };
  // 鼠标 hover
  const hoverSx = useMemo(
    () => ({
      borderRadius: '16px',
      boxShadow: active
        ? '0px 12px 24px -4px rgba(145, 158, 171, 0.30), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)'
        : '0px 12px 24px -4px rgba(145, 158, 171, 0), 0px 0px 2px 0px rgba(145, 158, 171, 0)',
      transformOrigin: '0 0 0',
      pb: 3,
      pt: 1,
      mb: 3,
      [`&:hover`]: {
        boxShadow:
          '0px 12px 24px -4px rgba(145, 158, 171, 0.30), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)',
      },
    }),
    [active]
  );
  return (
    <Stack
      onClick={() => {
        gotoClusterRiskDetail();
      }}
      direction="column"
      bgcolor="common.white"
      sx={{
        ...Object.assign(commonRadius!, sx!)!,
        ...hoverSx,
        py: 1.7,
        px: 3,
      }}
    >
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={3.5}>
          <Stack direction="row">
            <Stack
              sx={{ position: 'relative', width: '120px', mb: -5, ml: -2 }}
              direction="column"
              justifyContent="center"
              alignContent="center"
            >
              <ReactEcharts
                className="echarts-for-echarts"
                option={getOption(data.inspect.total, data.inspect.pass)}
                notMerge
                lazyUpdate
                style={{ width: '120px', height: '120px' }}
              />
              <Stack
                style={{ position: 'absolute', top: '35px', width: '100%' }}
                sx={{ textAlign: 'center' }}
              >
                {isInspectEmpty ? '--' : t(levelItem?.label ?? data.level)}
              </Stack>
              <Stack
                style={{ position: 'absolute', top: '60', width: '100%' }}
                sx={{ textAlign: 'center' }}
              >
                <Typography variant="caption" sx={{ mt: 1 }} color="text.secondary">
                  {t('risk_deal_manage.pass')} <b>{isInspectEmpty ? '--' : data.inspect.pass}</b>
                  &nbsp;/&nbsp;
                  {isInspectEmpty ? '--' : data.inspect.total}
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="column"
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="start"
              flex={1}
              sx={{ p: 1 }}
            >
              <Typography variant="h6" sx={{ wordBreak: 'break-all' }}>
                {data.name}
              </Typography>
              <Stack direction="row">
                <Label>{data.mqVersion} </Label>
                <Typography variant="body2" color="text.secondary" sx={{ mx: 0.5 }}>
                  {t('cluster.master_and_slave', {
                    master: masterNode,
                    slave: slaveNode,
                  })}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={8.5}>
          <Stack direction="row" height="100%">
            <Stack direction="row" flex={1} justifyContent="space-between" sx={{ pb: 1 }}>
              <Grid item container xs={12} spacing={2}>
                {[
                  {
                    title: t('risk_deal_manage.produce_tps_peek'),
                    data: data.overview.produceTps,
                    unit: t('risk_deal_manage.cluster_tps_unit'),
                    subTitle: t('risk_deal_manage.cluster_risk'),
                    subKey: 'cluster',
                  },
                  {
                    title: t('risk_deal_manage.consume_tps_peek'),
                    data: data.overview.consumeTps,
                    unit: t('risk_deal_manage.cluster_tps_unit'),
                    subTitle: t('risk_deal_manage.topic_risk'),
                    subKey: 'topic',
                  },
                  {
                    title: t('risk_deal_manage.topic_count'),
                    data: data.overview.topicTotal,
                    unit: t('indivual'),
                    subTitle: t('risk_deal_manage.group_risk'),
                    subKey: 'consumergroup',
                  },
                  /* {
                    title: t('risk_deal_manage.topic_count'),
                    data: `${data.overview.topicTotal}`,
                    unit: t('indivual'),
                  },
                  {
                    title: t('risk_deal_manage.group_count'),
                    data: `${data.overview.groupTotal}`,
                    unit: t('indivual'),
                  }, */
                ].map((item, index) => {
                  const riskCounts = data.riskCountByType[
                    item.subKey as keyof typeof data.riskCountByType
                  ] ?? {
                    p0: 0,
                    p1: 0,
                    p2: 0,
                  };
                  return (
                    <Grid item xs={4} key={`${data.clusterId}${item.title}`}>
                      <Stack direction="column" alignContent="flex-end" gap={0.5}>
                        <Stack
                          sx={{ height: '100%' }}
                          direction="column"
                          justifyContent="space-between"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            sx={{ mt: 2, ml: index === 0 ? 1.5 : 0, mr: index === 2 ? 1.5 : 0 }}
                          >
                            <Typography variant="body2" color="text.disabled" noWrap>
                              {item.title}
                            </Typography>
                            <Typography variant="h6" color="text.secondary" sx={{ ml: 1 }}>
                              {item.data}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.disabled"
                              sx={{ ml: 1 }}
                              justifyContent="center"
                            >
                              {item.unit}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack alignItems="flex-start">
                          <Label
                            sx={{
                              width: '100%',
                              justifyContent: 'flex-start !important',
                            }}
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            flexWrap="wrap"
                          >
                            <Stack sx={{ mr: 1 }} justifyContent="center">
                              {item.subTitle}
                            </Stack>
                            <Wrapper show={riskCounts.p0 > 0}>
                              <Stack direction="row" alignItems="center" sx={{ mr: 2.5 }}>
                                <Stack
                                  sx={{
                                    background: riskLevels.find((cv) => cv.value === 'P0')?.color,
                                    ...statusLabel,
                                    mr: 1,
                                  }}
                                />
                                {riskCounts.p0} {t('risk_deal_manage.risk_count_unit')}
                              </Stack>
                            </Wrapper>
                            <Wrapper show={riskCounts.p1 > 0}>
                              <Stack direction="row" alignItems="center" sx={{ mr: 2.5 }}>
                                <Stack
                                  sx={{
                                    background: riskLevels.find((cv) => cv.value === 'P1')?.color,
                                    ...statusLabel,
                                    mr: 1,
                                  }}
                                />
                                {riskCounts.p1} {t('risk_deal_manage.risk_count_unit')}
                              </Stack>
                            </Wrapper>
                            <Wrapper show={riskCounts.p2 > 0}>
                              <Stack direction="row" alignItems="center" sx={{ mr: 2.5 }}>
                                <Stack
                                  sx={{
                                    background: riskLevels.find((cv) => cv.value === 'P2')?.color,
                                    ...statusLabel,
                                    mr: 1,
                                  }}
                                />
                                {riskCounts.p2} {t('risk_deal_manage.risk_count_unit')}
                              </Stack>
                            </Wrapper>
                            <Wrapper show={isInspectEmpty}>--</Wrapper>
                            <Wrapper
                              show={
                                !isInspectEmpty &&
                                !Object.values(riskCounts).some((cv) => Boolean(cv))
                              }
                            >
                              <Stack direction="row" alignItems="center" sx={{ mr: 2.5 }}>
                                <Stack
                                  sx={{
                                    background: riskLevels.find((cv) => cv.value === 'HEALTH')
                                      ?.color,
                                    ...statusLabel,
                                    mr: 1,
                                  }}
                                />
                                {t('risk_deal_manage.no_risk')}
                              </Stack>
                            </Wrapper>
                          </Label>
                        </Stack>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
