import { useCallback, useEffect } from 'react';
// routes
import { useRouter } from 'src/routes/hooks';
import { useLocation } from 'react-router-dom';
import { useClusterGroupContext } from '../../contexts/cluster-group/use-cluster-group-context';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function SplashScreenGuard({ children }: Props) {
  const router = useRouter();
  const location = useLocation();

  const { selectedClusterGroupId } = useClusterGroupContext();

  const check = useCallback(() => {
    if (location.pathname === '/') {
      if (selectedClusterGroupId) {
        router.push(`/group/${selectedClusterGroupId}/system-inspect/risk-panel`);
      }
    }
  }, [router, location.pathname, selectedClusterGroupId]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
