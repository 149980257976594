import { useEffect, useCallback, useState } from 'react';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    setChecked(true);
    // if (!authenticated) {
    //   const searchParams = new URLSearchParams({
    //     returnTo: window.location.pathname,
    //   }).toString();
    //
    //   const loginPath = loginPaths[method];
    //   const href = `${loginPath}?${searchParams}`;
    //   router.replace(href);
    // } else {
    //   setChecked(true);
    // }
  }, []);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
