// @mui
import { useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import { CardContent, Link, Typography } from '@mui/material';
import bgPng from 'src/images/activation.png';
import { useLicenseStatusContext } from 'src/contexts/license-status/use-license-status-context';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { postActivate } from 'src/api/system-api';
import { useLicenseForm } from 'src/hooks/use-system-manage';
import Image from '../../../../components/image';
import Iconify from '../../../../components/iconify';
import { useLocales } from '../../../../locales';
import Logo from '../../../../components/logo';
// hooks

// ----------------------------------------------------------------------
type Props = {
  open: boolean;
  setOpen: (arg0: boolean) => void;
};
export default function UpdateActivation({ open, setOpen }: Props) {
  const { t } = useLocales();
  const radioGroupRef = useRef<HTMLElement>(null);
  const { installID, mutation } = useLicenseStatusContext();
  const { copy } = useCopyToClipboard();
  const [hoverOpen, setHoverOpen] = useState(false);
  const handleEntering = () => {
    radioGroupRef.current?.focus();
  };
  const methods = useLicenseForm();
  const handleUpdate = async (data: any) => {
    try {
      await postActivate({
        licenseCode: data.secretKey,
      });
      await mutation();
      setHoverOpen(false);
      setOpen(false);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  // const handleTrial = async (data: any) => {
  //   try {
  //     await postFreeTrial();
  //     await mutation();
  //     setHoverOpen(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      id="ringtone-menu"
      open={open}
      onClose={onClose}
      TransitionProps={{ onEntering: handleEntering }}
      maxWidth="lg"
      sx={{ '& .MuiDialog-paper': { width: 802 } }}
    >
      <DialogContent sx={{ px: 4, py: 3, borderRadius: 0 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Card sx={{ boxShadow: 'none', width: '380px', borderRadius: 0 }}>
              <Stack direction="row" alignItems="center" sx={{ mb: 6.5 }}>
                <Stack
                  typography="body1"
                  color="text.primary"
                  bgcolor="primary.main"
                  sx={{
                    borderRadius: '8px',
                    my: 1,
                    mr: 1.5,
                    width: '46px',
                    height: '46px',
                  }}
                >
                  <Logo sx={{ m: 'auto' }} />
                </Stack>
                <Typography variant="subtitle1" color="text.primary">
                  AutoMQ Copilot for RocketMQ
                </Typography>
              </Stack>
              <CardContent sx={{ px: 0, py: 0 }}>
                <Typography variant="subtitle2" color="text.primary" sx={{ mb: 1 }}>
                  {t('system.copy_installation_id_tips')}
                  <Link
                    variant="inherit"
                    href="https://home.automq.com"
                    color="primary"
                    underline="none"
                    target="_blank"
                  >
                    {t('system.create_product_key')}
                    <Iconify icon="mdi:copy" />
                  </Link>
                </Typography>
                <Stack
                  color="text.primary"
                  bgcolor="warning.lighter"
                  onMouseEnter={() => {
                    setHoverOpen(true);
                  }}
                  onMouseLeave={() => {
                    setHoverOpen(false);
                  }}
                  onClick={() => {
                    copy(installID);
                  }}
                  sx={{
                    border: '1px solid rgba(122, 65, 0, 0.10)',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    fontSize: '12px',
                    fontWeight: '600',
                    position: 'relative',
                    textAlign: 'center',
                    lineHeight: '20px',
                    px: 2,
                    py: 1,
                    mb: 1,
                  }}
                >
                  {installID}
                  {hoverOpen && (
                    <Stack
                      color="text.primary"
                      bgcolor="common.white"
                      sx={{
                        boxShadow:
                          '-20px 20px 40px -4px rgba(145, 158, 171, 0.24), 0px 0px 2px 0px rgba(145, 158, 171, 0.24)',
                        border: '1px solid rgba(122, 65, 0, 0.10)',
                        position: 'absolute',
                        right: 0,
                        top: '-58px',
                        textAlign: 'center',
                        fontWeight: '400',
                        px: 1.5,
                        py: 1.5,
                      }}
                    >
                      <Stack
                        sx={{
                          position: 'relative',
                        }}
                      >
                        {t('click_and_copy')}
                        <Iconify
                          color="common.white"
                          sx={{
                            bottom: '-22px',
                            left: '22px',
                            position: 'absolute',
                          }}
                          icon="teenyicons:down-solid"
                        />
                      </Stack>
                    </Stack>
                  )}
                </Stack>
                <Typography variant="caption" color="text.secondary">
                  {t('system.activation_tips')}
                </Typography>
                <Stack>
                  <Typography color="text.primary" variant="subtitle2" sx={{ mt: 2.5, mb: 1 }}>
                    {t('system.activation_for_key')}
                  </Typography>
                  <FormProvider methods={methods}>
                    <Stack gap={2}>
                      <RHFTextField
                        multiline
                        margin="dense"
                        name="secretKey"
                        rows={4}
                        label=""
                        placeholder={t('system.please_enter_product_key')}
                      />
                    </Stack>
                  </FormProvider>
                </Stack>
              </CardContent>
              <Button
                color="primary"
                sx={{ mt: 3.7, mb: 1.5, width: '100%' }}
                variant="contained"
                onClick={methods.handleSubmit(handleUpdate)}
              >
                {t('system.enroll_to_activation')}
              </Button>
              <Button sx={{ width: '100%' }} variant="outlined" onClick={onClose}>
                {t('back')}
              </Button>
            </Card>
          </Stack>
          <Stack sx={{ borderRadius: '16px', overflow: 'hidden' }}>
            <Image height="100%" src={bgPng} />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
