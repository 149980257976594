import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
// utils
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
// types
import { ICluster } from 'src/types/cluster';
// components
import FormProvider, { RHFAutocomplete, RHFSwitch, RHFTextField } from 'src/components/hook-form';
import { Button, Typography } from '@mui/material';
import { integrateCluster, listClustersDetailRealTime, updateCluster } from 'src/api/cluster-api';
import { IDynamicCluster } from 'src/types/cluster-group';
import { useClusterGroupContext } from 'src/contexts/cluster-group/use-cluster-group-context';
import { IBroker } from 'src/types/broker';
import MainPaper from 'src/components/main-paper';
import { Wrapper } from 'src/components/wrapper';
import { useTranslation } from 'react-i18next';
import BrokerTableView from './broker-table';
import Iconify from '../../../../components/iconify';

type Props = {
  currentCluster?: ICluster;
};

function useClusterCreateForm({ currentCluster }: Props) {
  const { t } = useTranslation();
  const newClusterSchema = Yup.object().shape({
    clusterName: Yup.string().required(t('cluster.integrate.cluster_name_required')),
    name: Yup.string()
      .required(t('cluster.integrate.cluster_alias_required'))
      .matches(
        /^[\u4e00-\u9fa5_a-zA-Z0-9]{3,64}$/,
        t('cluster.integrate.cluster_alias_reg_exp')
      ),
    remark: Yup.string()
      .matches(/^$|^[\u4e00-\u9fa5_a-zA-Z0-9\s]{3,128}$/, t('cluster.integrate.cluster_remark_reg_exp')),
    enableSLI: Yup.boolean(),
    topicForSLI: Yup.string().when('enableSLI', {
      is: (value: boolean) => value,
      then: (schema) => schema.required(t('cluster.integrate.topic_for_sli_required')),
    }),
  });
  const defaultValues = useMemo(
    () => ({
      name: currentCluster?.name || '',
      clusterName: currentCluster?.clusterName || '',
      remark: currentCluster?.remark || '',
      enableSLI: currentCluster?.enableSLI || false,
      topicForSLI: currentCluster?.topicForSLI || '',
    }),
    [currentCluster]
  );

  const methods = useForm({
    resolver: yupResolver(newClusterSchema),
    defaultValues,
  });
  useEffect(() => {
    methods.reset(defaultValues);
  }, [methods, defaultValues]);
  return methods;
}

export default function ClusterNewEditForm({ currentCluster }: Props) {
  const { t } = useTranslation();
  const { selectedClusterGroupId } = useClusterGroupContext();
  const isEdit = currentCluster != null;
  const router = useRouter();
  // const { enqueueSnackbar } = useSnackbar();

  const [selectedCluster, setSelectedCluster] = useState<string>('');
  const [brokers, setBrokers] = useState<IBroker[]>([]);
  const methods = useClusterCreateForm({ currentCluster });
  // 防止重复提交
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
    getValues,
  } = methods;
  const [enableSLI, topicForSLI] = watch(['enableSLI', 'topicForSLI']);
  if (enableSLI && !topicForSLI) {
    const clusterName = getValues('clusterName');
    if (clusterName) {
      setValue('topicForSLI', clusterName);
    }
  }
  useEffect(() => {
    setSelectedCluster(currentCluster?.clusterName || '');
  }, [currentCluster]);

  const [clusters, setClusters] = useState<IDynamicCluster[]>([]);
  const onGetClusterList = useCallback(async () => {
    try {
      setClusters(
        (await listClustersDetailRealTime(selectedClusterGroupId!)).filter(
          (cluster) => isEdit || !cluster.managed
        )
      );
    } catch (error) {
      /* empty */
    }
  }, [selectedClusterGroupId, isEdit]);
  useEffect(() => {
    onGetClusterList();
  }, [onGetClusterList]);
  useEffect(() => {
    if (selectedCluster) {
      const data = clusters.filter((cluster) => cluster.name === selectedCluster);
      setBrokers((data.length && data[0]?.brokers) || []);
    }
  }, [clusters, selectedCluster]);
  const { initialize } = useClusterGroupContext();
  const onSubmit = handleSubmit(async (data) => {
    if (isEdit) {
      await updateCluster(selectedClusterGroupId!, {
        ...data,
        groupId: selectedClusterGroupId,
        clusterId: currentCluster.clusterId!,
      });
    } else {
      await integrateCluster(selectedClusterGroupId!, {
        ...data,
        groupId: selectedClusterGroupId,
      });
    }
    initialize();

    // enqueueSnackbar(isEdit ? t('updateComplete') : t('cluster.access_successful'));
    if (!isEdit) {
      reset();
    }
    router.push(paths.dashboard.cluster.list(selectedClusterGroupId!));
  });

  const cancelEditCluster = () => {
    router.push(paths.dashboard.cluster.list(selectedClusterGroupId!));
  };
  const [openAcl, setOpenAcl] = useState(false);
  const showAcl = () => {
    setOpenAcl(!openAcl);
  };
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3}>
        <RHFTextField
          size="medium"
          name="name"
          label={t('cluster.alias.label')}
          helperText={t('cluster.alias.help_text')}
          placeholder={t('cluster.alias.placeholder')}
          style={{ width: '676px' }}
        />
        <RHFTextField
          size="medium"
          name="remark"
          label={t('cluster.note.label')}
          helperText={t('cluster.note.help_text')}
          placeholder={t('cluster.note.placeholder')}
          style={{ width: '676px' }}
        />
        <RHFAutocomplete
          label="ClusterName"
          size="medium"
          disabled={isEdit}
          helperText={t('cluster.name.help_text')}
          name="clusterName"
          onValueChange={(value) => {
            setSelectedCluster(value);
          }}
          options={clusters.map((cluster) => cluster.name)}
          noOptionsText={t('unmanaged')}
          getOptionLabel={(option) => option}
          style={{ width: '676px' }}
          isOptionEqualToValue={(option, value) => option === value}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option}
              style={{
                padding: '10px',
              }}
            >
              {option}
            </li>
          )}
        />
        <Typography variant="subtitle2" sx={{ mb: -1.5 }} fontWeight="600">
          {t('broker')}
        </Typography>
        <BrokerTableView brokers={brokers} />
        <Stack onClick={showAcl} sx={{ cursor: 'pointer' }} direction="row" alignItems="center">
          <Iconify
            width="14px"
            color="text.secondary"
            sx={{ mr: 1 }}
            icon={openAcl ? 'icon-park-outline:right-c' : 'icon-park-outline:up-c'}
          />
          <Stack>
            <Typography variant="subtitle2" color="text.secondary">
              {t('advanced_configuration')}
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="caption" color="text.secondary">
          {t('copilot.sli.caption')}
        </Typography>
        <Stack direction="row" alignItems="center" sx={{ mx: 0, mb: -1.5 }}>
          <Typography variant="body2" color="text.primary">
            {t('sli.probe')}
          </Typography>
          <RHFSwitch sx={{ mx: 2 }} name="enableSLI" label="" />
        </Stack>
        <Wrapper show={enableSLI}>
          <RHFTextField
            size="medium"
            name="topicForSLI"
            label={t('cluster.integrate.topic_for_sli')}
            helperText={t('topicForSLINote')}
            placeholder={`${t('cluster.integrate.topic_for_sli_help_text')}}*`}
            style={{ width: '676px' }}
          />
        </Wrapper>
        {/* <>
          <Stack
            direction="row"
            alignItems="center"
            sx={{  mx: 0, mb: -1.5 }}
          >
          <Typography variant="body2" color="text.primary">
            {t('cluster.ACL_auth')}
          </Typography>
            <RHFSwitch sx={{ mx: 2 }} name="enableSLI" label="" />
          </Stack>
          <Stack
            direction="column"
            bgcolor="grey.100"
            sx={{
              borderRadius: '8px',
              px: 2.5,
              py: 2.5,
              width: '676px',
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {t('cluster.ACL_username_pw')}
            </Typography>
            <RHFTextField
              name="username"
              label={t('username')}
              placeholder=""
              sx={{ my: 1.5 }}
              style={{ width: '370px' }}
            />
            <RHFTextField
              name="password"
              label={t('Password')}
              placeholder=""
              style={{ width: '370px' }}
            />
          </Stack>
        </> */}
        {/* <Stack spacing={2}>
          <Typography variant="subtitle2">集群别名</Typography>
          <FormLabel required>
            <RHFTextField
              name="name"
              helperText="说明：根据最佳实践，一般建议按照不同业务系统，划分不同的集群。"
              placeholder="设置一个集群别名，用于区分识别"
              style={{ width: '600px' }}
            />
          </FormLabel>
        </Stack>
        <Stack spacing={2}>
          <Typography variant="subtitle2">集群备注</Typography>
          <FormLabel required>
            <RHFTextField
              name="remark"
              style={{ width: '600px' }}
              helperText="说明：根据最佳实践，一般建议按照不同业务系统，划分不同的集群。"
              placeholder="设置一个集群备注，用于区分识别"
            />
          </FormLabel>
        </Stack>
        <Typography variant="subtitle2">ClusterName</Typography>
        <FormLabel required={!isEdit}>
          <RHFAutocomplete
            disabled={isEdit}
            helperText="说明：当前环境（NameServer 分组）下每个 Cluster 只允许添加一次。"
            name="clusterName"
            onValueChange={(value) => {
              setSelectedCluster(value);
            }}
            options={clusters.map((cluster) => cluster.name)}
            noOptionsText="当前环境下不存在未托管的集群"
            getOptionLabel={(option) => option}
            style={{ width: '600px' }}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option}
                style={{
                  padding: '10px',
                }}
              >
                {option}
              </li>
            )}
          />
        </FormLabel>
        <Typography variant="subtitle2">Broker 列表信息</Typography>
        <BrokerTableView brokers={brokers} /> */}
        <MainPaper justifyContent="flex-start">
          <LoadingButton
            color="primary"
            sx={{ height: '36px' }}
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {isEdit ? t('saveChanges') : t('oK')}
          </LoadingButton>
          <Button
            variant="outlined"
            sx={{ whiteSpace: 'nowrap', height: '36px' }}
            onClick={cancelEditCluster}
          >
            {t('cancel')}
          </Button>
        </MainPaper>
      </Stack>
    </FormProvider>
  );
}
