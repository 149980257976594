import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
// const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <GuestGuard>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </GuestGuard>
  ),
  // children: [
  //   {
  //     path: 'login',
  //     element: (
  //       <AuthClassicLayout>
  //         <JwtLoginPage />
  //       </AuthClassicLayout>
  //     ),
  //   },
  // ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
