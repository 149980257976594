import {
  Button,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import { useLocales } from 'src/locales';
import { useState } from 'react';
import { ClusterEditDialog } from './components/cluster-edit-dialog';
import { ClusterDelDialog } from './components/cluster-del-dialog';

export default function RuleEditView() {
  const { t } = useLocales();
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  const updateCluster = async (data: any) => {
    console.log(data);
  };
  // 删除确认
  const [delopen, setDelOpen] = useState(false);
  const onCloseDelOpen = () => {
    setDelOpen(false);
  };
  const delCluster = async (data: any) => {
    console.log(data);
  };

  const TABLE_HEAD = [
    {
      id: 'resourceType',
      label: t('resource_type'),
    },
    {
      id: 'clusterName',
      label: t('cluster.name.label'),
    },
    {
      id: 'resourceName',
      label: t('topic_name'),
    },
    {
      id: 'addAt',
      label: t('add_time'),
    },
    {
      id: 'action',
      label: t('action'),
    },
  ];
  return (
    <Stack>
      <CustomBreadcrumbs
        heading={t('inspection_manage.edit_inspect_rule')}
        description={t('inspection_manage.edit_inspect_rule_description')}
        links={[
          {
            name: t('system_inspect'),
            href: '',
          },
          {
            name: t('inspection_manage.white_list_edit'),
            href: '',
          },
        ]}
        sx={{ my: 3 }}
        action={
          <>
            <Button sx={{ py: 0.9, mx: 1.5 }} variant="outlined">
              <Iconify icon="material-symbols:refresh" />
            </Button>
            <Button color="primary" variant="contained">
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                onClick={() => {
                  setOpen(true);
                }}
              >
                <Iconify icon="mdi:add" />
                {t('inspection_manage.add_white_list')}
              </Stack>
            </Button>
            <ClusterEditDialog open={open} onClose={onClose} onEdit={updateCluster} />
          </>
        }
      />
      <Card sx={{ pb: 2 }}>
        <Stack direction="column" spacing={1}>
          {/* <Stack direction="row" justifyContent="space-between" spacing={2} sx={{ padding: 2 }}>
            <ListItemText
              primary={t('inspection_manage.edit_white_list')}
              secondary={t('inspection_manage.edit_white_list_description')}
              primaryTypographyProps={{ typography: 'h4' }}
              secondaryTypographyProps={{
                component: 'span',
                color: 'text.disabled',
              }}
            />
            <Stack direction="row" alignItems="flex-start">
              <IconButton color="default">
                <Iconify icon="eva:question-mark-circle-outline" />
              </IconButton>
              <IconButton
                color="default"
                onClick={() => {
                  router.back();
                }}
              >
                <Iconify icon="eva:close-fill" />
              </IconButton>
            </Stack>
          </Stack>
          <Box component="span" sx={{ p: 2 }}>
            <Button variant="contained" color="primary">
              {t('inspection_manage.add_white_list')}
            </Button>
          </Box> */}
          <TableContainer>
            <Scrollbar>
              <Table size="medium" sx={{ minWidth: 800 }}>
                <TableHeadCustom headLabel={TABLE_HEAD} />
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body2" color="text.primary" textOverflow="ellipsis">
                        12323
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="text.primary" textOverflow="ellipsis">
                        12323
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="text.primary" textOverflow="ellipsis">
                        12323
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="text.primary" textOverflow="ellipsis">
                        12323
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Link
                        color="primary"
                        variant="body2"
                        sx={{ cursor: 'pointer', textDecoration: 'none' }}
                        onClick={() => {
                          setDelOpen(true);
                        }}
                      >
                        {t('del')}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableNoData notFound />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          <ClusterDelDialog open={delopen} onClose={onCloseDelOpen} onEdit={delCluster} />
        </Stack>
      </Card>
    </Stack>
  );
}
