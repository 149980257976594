import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
import Iconify from '../iconify';

export default function CopyButton({ content }: { content: string }) {
  const { copy } = useCopyToClipboard();
  return (
    <Iconify
      icon="ph:copy-light"
      width={18}
      sx={{ mx: 0.5 }}
      onClick={() => {
        copy(content);
      }}
    />
  );
}
