import { Button, DialogActions, DialogContent, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Iconify from 'src/components/iconify';
import useConfirm from '../../../hooks/use-confirm';

export function ConfirmDialog() {
  const { t } = useTranslation();
  const { open, title, content, confirm, variant, closeConfirmDialog } = useConfirm();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      sx={{ '& .MuiDialog-paper': { minWidth: 550 } }}
      onClose={closeConfirmDialog}
    >
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          {title}
          <IconButton onClick={closeConfirmDialog}>
            <Iconify icon="mingcute:close-line" width={18} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.primary">
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button sx={{ height: '36px' }} variant="outlined" onClick={closeConfirmDialog}>
          {t('cancel')}
        </Button>
        <Button
          color={variant === 'error' ? 'error' : 'primary'}
          sx={{ height: '36px' }}
          variant={variant === 'error' ? 'contained' : 'outlined'}
          onClick={confirm}
        >
          {t('oK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
