// @mui
import { CircularProgress, LinearProgress } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

export default function LoadingScreen({
  sx,
  type = 'line',
  ...other
}: BoxProps & { type?: 'circle' | 'line' }) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      {type === 'line' && <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />}
      {type === 'circle' && <CircularProgress color="info" />}
    </Box>
  );
}
