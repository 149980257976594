import { Card, CardContent, Grid, Stack, Typography, alpha } from '@mui/material';
import Button from '@mui/material/Button';
import { useCallback, useEffect, useState } from 'react';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { TablePaginationCustom, useTable } from 'src/components/table';
import useGroupName from 'src/hooks/use-group-name';
import { useLocales } from 'src/locales';
import { Pager } from 'src/types/base';
import { getGroupRiskOverview, searchClusterOverviews } from 'src/api/inspect-api';
import { IClusterGroupRiskOverviewVO, IClusterOverviewVO } from 'src/types/inspect';
import Divider from '@mui/material/Divider';
import { usePalette } from 'src/hooks/use-palette';
import Iconify from 'src/components/iconify';
import AnalyticsData from './components/analytics-data';
import AnalyticsGroupSummary from './components/analytics-group-summary';
import PanelSearchBar from './components/panel-search-bar';
import PanelClusterRow from './components/panel-cluster-row';

export default function RiskPanelView() {
  const { t } = useLocales();
  const { groupId } = useGroupName();
  const commonRadius = {
    borderRadius: 2,
  };
  const table = useTable();
  const [groupOverview, setGroupOverview] = useState<IClusterGroupRiskOverviewVO | null>(null);
  const [data, setData] = useState<Pager<IClusterOverviewVO>>({
    total: 0,
    list: [],
    pageNum: 0,
    pageSize: 1,
  });
  const loadClusterOverviews = useCallback(
    async (
      keywords: string = '',
      riskLevel: string | undefined = undefined,
      order: 'asc' | 'desc' = 'asc',
      pageNum = table.page + 1,
      pageSize = table.rowsPerPage
    ) => {
      try {
        setData(
          await searchClusterOverviews(groupId!, {
            pageNum,
            pageSize,
            keywords,
            order,
            riskLevel,
          })
        );
        setGroupOverview(await getGroupRiskOverview(groupId!));
      } catch (error) {
        console.log(error);
      }
    },
    [groupId, table.page, table.rowsPerPage, setData]
  );
  const loadGroupOverview = useCallback(async () => {
    if (!groupId) {
      return;
    }
    try {
      setGroupOverview(await getGroupRiskOverview(groupId!));
    } catch (error) {
      console.log(error);
    }
  }, [groupId, setGroupOverview]);
  useEffect(() => {
    loadClusterOverviews();
  }, [loadClusterOverviews]);
  useEffect(() => {
    loadGroupOverview();
  }, [loadGroupOverview]);
  const { paletteColor } = usePalette();
  return (
    <Stack>
      <div>
        <CustomBreadcrumbs
          heading={t('risk_deal_manage.risk_deal_panel')}
          description={t('risk_deal_manage.risk_deal_panel_description')}
          links={[]}
          sx={{ my: 3 }}
          action={
            <Button
              variant="outlined"
              sx={{ mx: 1.5, height: '36px' }}
              onClick={() => {
                loadClusterOverviews();
              }}
            >
              <Iconify sx={{ verticalAlign: 'middle' }} icon="material-symbols:refresh" />
            </Button>
          }
        />
        <Grid container spacing={3}>
          <Grid item sx={{ minWidth: '360px!important' }}>
            <AnalyticsGroupSummary
              groupInfo={{
                total: groupOverview?.totalCount || 0,
                serious: groupOverview?.seriousCount || 0,
                urgent: groupOverview?.urgencyCount || 0,
                healthy: groupOverview?.healthyCount || 0,
                p0count: groupOverview?.p0count || 0,
                p1count: groupOverview?.p1count || 0,
                p2count: groupOverview?.p2count || 0,
              }}
              sx={{ height: '128px', maxWidth: '360px' }}
            />
          </Grid>
          <Grid item sx={{ minWidth: 'calc(100% - 360px)!important' }}>
            <Stack direction="column" height={128}>
              <Card
                sx={{
                  background: paletteColor.grey[100],
                  border: `1px solid ${paletteColor.border.normal}`,
                }}
              >
                <Typography
                  color="text.primary"
                  variant="caption"
                  textAlign="center"
                  alignItems="center"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    borderBottomRightRadius: '16px',
                    background: alpha(paletteColor.common.black, 0.08),
                    width: '110px',
                    height: '24px',
                    lineHeight: '24px',
                  }}
                >
                  {t('risk_deal_manage.cluster_group_overview')}
                </Typography>
                <CardContent sx={{ py: 2.5 }}>
                  <Stack direction="row" justifyContent="space-between" sx={{ py: 2.35 }}>
                    {groupOverview &&
                      [
                        {
                          title: t('risk_deal_manage.produce_tps_peek'),
                          data: groupOverview.produceTps,
                          unit: t('risk_deal_manage.cluster_tps_unit'),
                        },
                        {
                          title: t('risk_deal_manage.consume_tps_peek'),
                          data: groupOverview.consumeTps,
                          unit: t('risk_deal_manage.cluster_tps_unit'),
                        },
                        // {
                        //   title: t('risk_deal_manage.storage_space'),
                        //   data: groupOverview.storageSpace,
                        //   unit: t('storage_unit'),
                        // },
                        {
                          title: t('risk_deal_manage.topic_count'),
                          data: groupOverview.topicTotal,
                          unit: t('indivual'),
                        },
                        {
                          title: t('risk_deal_manage.consumer_group_count'),
                          data: groupOverview.groupTotal,
                          unit: t('indivual'),
                        },
                        // {
                        //   title: t('risk_deal_manage.client_connection_count'),
                        //   data: groupOverview.connection,
                        //   unit: t('indivual'),
                        // },
                      ].map((item) => (
                        <AnalyticsData
                          key={item.title}
                          title={item.title}
                          data={item.data}
                          unit={item.unit}
                        />
                      ))}
                  </Stack>
                </CardContent>
              </Card>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="column">
              <PanelSearchBar
                onFilter={(keywords: string, status: string | undefined, order: 'asc' | 'desc') => {
                  loadClusterOverviews(keywords, status, order, 1);
                }}
              />
              <Stack direction="column" sx={{ p: 0, ...commonRadius }}>
                {data.list.map((item) => (
                  <div key={item.clusterId}>
                    <PanelClusterRow key={item.clusterId} data={item} commonRadius={commonRadius} />
                    <Divider sx={{ mb: 1 }} />
                  </div>
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TablePaginationCustom
              count={data.total}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </div>
    </Stack>
  );
}
