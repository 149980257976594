import { useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
// routes
import Cookies from 'js-cookie';
import { useRouter } from '../hooks';

type Props = {
  children: React.ReactNode;
};

export default function TempCIdGuard({ children }: Props) {
  const cookieKey = 'copilot-tempCId';
  const router = useRouter();

  const check = useCallback(() => {
    if (!Cookies.get(cookieKey)) {
      const UUID = uuidv4();
      Cookies.set(cookieKey, UUID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
