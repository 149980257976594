import { LicenseData, SystemMetaData } from 'src/types/system';
import { get, postForm } from 'src/utils/axios';

export const getSystemMetaData = () => get<SystemMetaData>(`/system/config`);
export const getInstallID = () => get<string>(`/system/installId`);
export const getLicenseData = () => get<LicenseData>(`/system/license`);

export const postActivate = (params: { licenseCode: string }) =>
  postForm<void>(`system/license/:activate`, params);
export const postFreeTrial = () => postForm<void>(`system/license/:trial`, {});
