import { useCallback, useEffect, useMemo, useState } from 'react';
import { getInstallID, getLicenseData, getSystemMetaData } from 'src/api/system-api';
import { LicenseData, SystemMetaData, SupportFeatures } from 'src/types/system';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBoolean } from './use-boolean';

export function useLicenseForm() {
  const { t } = useTranslation();
  const UpdateSchema = Yup.object().shape({
    secretKey: Yup.string().required(t('common.validator.common.required')),
  });
  const defaultValues = {
    secretKey: '',
  };
  const methods = useForm({
    resolver: yupResolver(UpdateSchema),
    defaultValues,
  });
  return methods;
}

enum LicenseStatus {
  UNACTIVATED = 'UNACTIVATED',
  AVAILABLE = 'AVAILABLE',
  EXPIRED = 'EXPIRED',
}
export function useLicenseStatus() {
  const [license, setLicense] = useState<LicenseData>();
  const [installID, setInstallID] = useState('');
  const [activateDialogOpen, setActivateDialogOpen] = useState(false);

  const getStatus = (_license: LicenseData | undefined) => {
    const isUnactivate = _license === undefined || _license?.status === LicenseStatus.UNACTIVATED;
    const isAvailable = _license !== undefined && _license?.status === LicenseStatus.AVAILABLE;
    const isExpired = _license !== undefined && _license?.status === LicenseStatus.EXPIRED;
    const willExpired =
      _license !== undefined && _license.remainingDays !== undefined && _license.remainingDays < 7;

    return {
      isAvailable,
      isExpired,
      isUnactivate,
      willExpired,
    };
  };
  const { value: loading, onFalse, onTrue } = useBoolean(false);
  const getLicense = async () => {
    if (loading) return;
    onTrue();
    try {
      const res = await getLicenseData();
      console.log('license data', res);
      setLicense(res);
    } catch (err) {
      console.log(err);
    }
    onFalse();
  };
  const _getInstallID = async () => {
    try {
      const res = await getInstallID();
      setInstallID(res);
    } catch (err) {
      console.log(err);
    }
  };
  const status = useMemo(() => getStatus(license), [license]);
  const hasFeature = useCallback(
    (feature: SupportFeatures) => !!license?.features?.find((item) => item.code === feature),
    [license]
  );
  useEffect(() => {
    getLicense();
    _getInstallID();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    ...status,
    activateDialogOpen,
    setActivateDialogOpen,
    loading,
    license,
    installID,
    mutation: getLicense,
    hasFeature,
  };
}

export function useSystemManage() {
  const [systemMeta, setSystemMeta] = useState<SystemMetaData>();

  const getSystemMeta = async () => {
    try {
      const res = await getSystemMetaData();
      setSystemMeta(res);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSystemMeta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    systemMeta,
    getSystemMeta,
  };
}
