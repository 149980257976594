// @mui
import { Helmet } from 'react-helmet-async';
import { useLocales } from 'src/locales';
import RuleEditView from 'src/sections/system-inspect/rule/rule-edit-view';

export default function Page() {
  const { t } = useLocales();
  return (
    <>
      <Helmet>
        <title>{t('inspection_manage.edit_white_list')}</title>
      </Helmet>
      <RuleEditView />
    </>
  );
}
