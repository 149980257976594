import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
// routes
import { useRouter } from 'src/routes/hooks';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// auth
// components
import { usePopover } from 'src/components/custom-popover';
import { useTranslation } from 'react-i18next';

import { paths } from 'src/routes/paths';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { Fragment } from 'react';
import Iconify from '../../components/iconify';
import AvatarIndex from './avatar-index';
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'system_administration',
    linkTo: paths.dashboard.system.management,
  },
  {
    label: 'link_to_home',
    linkTo: 'https://www.automq.com',
  },
];

// ----------------------------------------------------------------------

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));
export default function AccountPopover() {
  const router = useRouter();

  const { user } = useMockedUser();

  const { t } = useTranslation();

  const popover = usePopover();

  const handleClickItem = (path: string) => {
    if (path.startsWith('http')) {
      window.open(path, '_blank');
    } else {
      popover.onClose();
      router.push(path);
    }
  };

  return (
    <>
      <Button
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ml: 1,
        }}
      >
        <Stack direction="row" alignItems="center">
          {/* <Avatar
            src={user?.photoURL}
            alt={user?.displayName}
            sx={{
              width: 36,
              height: 36,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
            }}
          >
            {user?.displayName.charAt(0).toUpperCase()}
          </Avatar> */}
          <AvatarIndex />
          {popover.open && (
            <Iconify
              color="common.white"
              width="14px"
              sx={{ ml: 1 }}
              icon="icon-park-solid:up-one"
            />
          )}
          {!popover.open && (
            <Iconify
              color="common.white"
              width="14px"
              sx={{ ml: 1 }}
              icon="icon-park-solid:down-one"
            />
          )}
        </Stack>
      </Button>

      <Popover
        open={Boolean(popover.open)}
        anchorEl={popover.open}
        onClose={popover.onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        slotProps={{
          paper: {
            sx: {
              p: 3,
              py: 1.5,
              width: 288,
            },
          },
        }}
      >
        <Box>
          <StyledAccount>
            <AvatarIndex />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user?.displayName}
              </Typography>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userId}
              </Typography> */}
            </Box>
          </StyledAccount>
        </Box>

        <Stack sx={{ p: 1, px: 0 }}>
          {OPTIONS.map((option) => (
            <Fragment key={option.label}>
              <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                <Typography variant="body2" color="text.primary" py={1}>
                  {t(option.label)}
                </Typography>
              </MenuItem>
              <Divider />
            </Fragment>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
