import { useContext } from 'react';
//
import { ClusterGroupContext } from './cluster-group-context';

// ----------------------------------------------------------------------

export const useClusterGroupContext = () => {
  const context = useContext(ClusterGroupContext);

  if (!context) throw new Error('useAuthContext context must be use inside ClusterGroupContext');

  return context;
};
