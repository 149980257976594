// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/`,
    // cluster
    clusterGroupManager: `${ROOTS.DASHBOARD}/cluster-group-manager`,
    addClusterGroupManager: `${ROOTS.DASHBOARD}/cluster-group-manager/add`,
    // 集群添加成功
    addClusterGroupSuccess: `${ROOTS.DASHBOARD}/cluster-group-manager/success`,
    // 集群组详情
    clusterGroupDetail: (groupId: string) =>
      `${ROOTS.DASHBOARD}/cluster-group-manager/${groupId}/detail`,
    noClusterGroup: `${ROOTS.DASHBOARD}/no-cluster-group`,
    cluster: {
      integrate: (groupId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/cluster-manager/integrate`,
      list: (groupId: string) => `${ROOTS.DASHBOARD}/group/${groupId}/cluster-manager`,
      edit: (groupId: string, clusterId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/cluster-manager/${clusterId}/edit`,
      detail: (groupId: string, clusterId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/cluster-manager/${clusterId}/detail`,
    },
    systemInspect: {
      // dashboard
      dashboard: (groupId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/system-inspect/dashboard`,
      inspectRule: (groupId: string) => `${ROOTS.DASHBOARD}/group/${groupId}/system-inspect/rule`,
      riskPanel: (groupId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/system-inspect/risk/panel`,
      editInspectRule: (groupId: string, inspectId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/system-inspect/rule/${inspectId}/edit`,
      inspectEvent: `${ROOTS.DASHBOARD}/system-inspect/event`,
      clusterRisk: (groupId: string, clusterId: string, tabId?: string) => {
        if (tabId) {
          return `${ROOTS.DASHBOARD}/group/${groupId}/system-inspect/risk/panel/${clusterId}#${tabId}`;
        }
        return `${ROOTS.DASHBOARD}/group/${groupId}/system-inspect/risk/panel/${clusterId}`;
      },
      // 新加入 - 调整风险处理详情
      riskDetail: (groupId: string, clusterId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/system-inspect/risk/panel/${clusterId}`,
      // 新加入 - 调整风险处理详情
      riskEdit: (groupId: string, clusterId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/system-inspect/risk/panel/${clusterId}/edit`,
      riskCreateAlertRule: (groupId: string, clusterId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/system-inspect/risk/panel/${clusterId}/alert-rule/create`,
    },
    expertDiagnosis: {
      history: (groupId: string) => `${ROOTS.DASHBOARD}/group/${groupId}/expert-diagnosis/panel/`,
      detail: (groupId: string, diagnosisId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/expert-diagnosis/panel/${diagnosisId}`,
      loading: (groupId: string, diagnosisId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/expert-diagnosis/panel/${diagnosisId}/loading`,
      add: (groupId: string) => `${ROOTS.DASHBOARD}/group/${groupId}/expert-diagnosis/panel/add`,
    },
    system: {
      userManagement: `${ROOTS.DASHBOARD}/users`,
      subscription: `${ROOTS.DASHBOARD}/sub-service`,
      management: `${ROOTS.DASHBOARD}/management`,
      createAlertChannelConfig: `${ROOTS.DASHBOARD}/management?createDialogOpened=1`,
    },
    expert: {
      diagnosis: `${ROOTS.DASHBOARD}`,
    },
    // SLI
    sli: {
      index: (groupId: string, clusterId?: string) => {
        if (clusterId) {
          return `${ROOTS.DASHBOARD}/group/${groupId}/sli#${clusterId}`;
        }
        return `${ROOTS.DASHBOARD}/group/${groupId}/sli`;
      },
    },
    slo: {
      index: (groupId: string) => `${ROOTS.DASHBOARD}/group/${groupId}/slo`,
      detail: (groupId: string, sloId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/slo/${sloId}/detail`,
      create: (groupId: string) => `${ROOTS.DASHBOARD}/group/${groupId}/slo/create`,
    },
    // 容量规划
    capacity: {
      index: (groupId: string) => `${ROOTS.DASHBOARD}/group/${groupId}/capacity`,
      detail: (groupId: string, capacityId: string) =>
        `${ROOTS.DASHBOARD}/group/${groupId}/capacity/${capacityId}/detail`,
      create: (groupId: string) => `${ROOTS.DASHBOARD}/group/${groupId}/capacity/create`,
    },
  },
};
