import { useClusterGroupContext } from 'src/contexts/cluster-group/use-cluster-group-context';
import { useParams } from 'src/routes/hooks';

export default function useGroupName(groupId?: string, defaultValue?: string) {
  const params = useParams();
  const { getGroupName } = useClusterGroupContext();
  const { selectedClusterGroupId } = useClusterGroupContext();
  if (!groupId) {
    groupId = params.groupId as string;
  }
  if (!groupId) {
    groupId = selectedClusterGroupId;
  }
  if (!groupId) {
    return {
      groupId,
      groupName: defaultValue || null,
    };
  }
  return {
    groupId,
    groupName: getGroupName(groupId) || defaultValue || null,
  };
}
