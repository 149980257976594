import { useMemo } from 'react';
// routes
// components
import SvgColor from 'src/components/svg-color';

import { useClusterGroupContext } from 'src/contexts/cluster-group/use-cluster-group-context';
import { useLocales } from 'src/locales';
// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { selectedClusterGroupId, clusterGroups } = useClusterGroupContext();
  const data = useMemo(() => {
    const groupId = `/group/${selectedClusterGroupId}`;
    const clusterGroupExist = clusterGroups.length > 0;
    return [
      {
        subheader: t('copilot.menu.dashboard.subheader'),
        items: [
          {
            title: t('copilot.menu.dashboard.title'),
            path: `${groupId}/system-inspect/dashboard`,
            icon: ICONS.dashboard,
            disabled: !clusterGroupExist,
          },
        ],
      },
      {
        subheader: t('system_inspect'),
        items: [
          {
            title: t('risk_deal'),
            path: `${groupId}/system-inspect/risk/panel`,
            icon: ICONS.analytics,
            disabled: !clusterGroupExist,
          },
          {
            title: t('inspect_rule'),
            path: `${groupId}/system-inspect/rule`,
            icon: ICONS.calendar,
            disabled: !clusterGroupExist,
          },
        ],
      },
      {
        // subheader: t('expert_diagnosis'),
        subheader: t('Management'),
        items: [
          {
            title: t('expert_diagnosis_manage.diagnosis_by_self'),
            path: `${groupId}/expert-diagnosis/panel`,
            icon: ICONS.disabled,
            disabled: !clusterGroupExist,
          },
        ],
      },
      {
        subheader: t('clusterGovernance'),
        items: [
          {
            title: t('SLI'),
            path: `${groupId}/sli`,
            icon: ICONS.label,
            disabled: !clusterGroupExist,
          },
          {
            title: t('slo.management'),
            path: `${groupId}/slo`,
            icon: ICONS.kanban,
            disabled: !clusterGroupExist,
          },
          {
            title: t('copilot.menu.capacity.title'),
            path: `${groupId}/capacity`,
            icon: ICONS.ecommerce,
            disabled: !clusterGroupExist,
          },
        ],
      },
      {
        subheader: t('system_administration'),
        items: [
          {
            title: t('cluster_management'),
            path: `${groupId}/cluster-manager`,
            icon: ICONS.dashboard,
            disabled: !clusterGroupExist,
          },
        ],
      },
    ];
  }, [selectedClusterGroupId, t, clusterGroups]);

  return data;
}
