import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { useLocales } from 'src/locales';
import { useEffect, useMemo, useRef, useState } from 'react';
import { riskLevels } from 'src/constants/risk';

export type Props = {
  onFilter: (keywords: string, status: string | undefined, order: 'asc' | 'desc') => void;
};

export default function PanelSearchBar({ onFilter }: Props) {
  const { t } = useLocales();
  const [statusValue, setStatusValue] = useState<string>('');
  const isFirst = useRef(true);
  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
    } else {
      onFilter('', statusValue === '' ? undefined : statusValue, 'asc');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusValue]);
  const renderOptions = useMemo(
    () =>
      riskLevels.concat([
        {
          // @ts-ignore
          value: undefined,
          label: 'select_none',
          color: '',
        },
      ]),
    []
  );
  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 2.5,
        pl: 0,
        pr: { xs: 2.5, md: 1 },
      }}
    >
      <FormControl
        sx={{
          flexShrink: 0,
          width: { xs: 1, md: 200 },
        }}
      >
        <InputLabel size="small" sx={{ height: '100%!important' }}>
          {t('risk_deal_manage.healthy_status')}
        </InputLabel>
        <Select
          size="small"
          value={statusValue}
          onChange={(e) => setStatusValue(e.target.value as string)}
          input={<OutlinedInput label={t('risk_deal_manage.healthy_status')} />}
          renderValue={(selected: string) =>
            t(renderOptions.find((item) => item.value === selected)?.label ?? '')
          }
          sx={{ textTransform: 'capitalize', bgcolor: 'white' }}
        >
          {renderOptions.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {t(option.label)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
