import { useContext } from 'react';
//
import { LicenseStatusContext } from './license-status-context';

// ----------------------------------------------------------------------

export const useLicenseStatusContext = () => {
  const context = useContext(LicenseStatusContext);

  if (!context)
    throw new Error('useLicenseStatusContext context must be use inside LicenseStatusContext');

  return context;
};
