import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { useClusterGroupContext } from 'src/contexts/cluster-group/use-cluster-group-context';
import { useParams } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';

import { RouteList } from 'src/routes/sections/dashboard';
import { should } from 'src/utils/assert';

// NOTE: 主要用来做面包屑路径，页面跳转建议直接跳用paths
export function useRoutePath() {
  const { selectedClusterGroupId } = useClusterGroupContext();
  const { clusterId } = useParams();
  // 固定路径 与环境组无关
  const rootPaths = useMemo(
    () => ({
      clusterGroupList: paths.dashboard.clusterGroupManager,
      systemManage: paths.dashboard.system.management,
      userManage: paths.dashboard.system.userManagement,
      subscriptionManage: paths.dashboard.system.subscription,
    }),
    []
  );
  // 与环境组有关
  const groupPaths = useMemo(
    () => ({
      riskPanel: paths.dashboard.systemInspect.riskPanel(selectedClusterGroupId!),
      clusterList: paths.dashboard.cluster.list(selectedClusterGroupId!),
      diagnosisList: paths.dashboard.expertDiagnosis.history(selectedClusterGroupId!),
    }),
    [selectedClusterGroupId]
  );
  // 与集群有关
  const clusterPaths = useMemo(
    () => ({
      riskDetail: paths.dashboard.systemInspect.riskDetail(selectedClusterGroupId!, clusterId!),
      clusterRisk: paths.dashboard.systemInspect.clusterRisk(selectedClusterGroupId!, clusterId!),
      riskEdit: paths.dashboard.systemInspect.riskEdit(selectedClusterGroupId!, clusterId!),
      clusterDetail: paths.dashboard.cluster.detail(selectedClusterGroupId!, clusterId!),
    }),
    [selectedClusterGroupId, clusterId]
  );

  // slo
  const sloPaths = useMemo(
    () => ({
      sloIndex: paths.dashboard.slo.index(selectedClusterGroupId!),
    }),
    [selectedClusterGroupId]
  );

  // sli
  const sliPaths = useMemo(
    () => ({
      sliIndex: paths.dashboard.sli.index(selectedClusterGroupId!),
    }),
    [selectedClusterGroupId]
  );

  return {
    ...rootPaths,
    ...groupPaths,
    ...clusterPaths,
    ...sloPaths,
    ...sliPaths,
  };
}

export function useRouteInfo() {
  const location = useLocation();
  const { pathname } = location;
  const curRoute = useMemo(
    () => RouteList.find((cv) => matchPath({ path: cv.path, end: true }, pathname)),
    [pathname]
  );
  should(curRoute);
  // 判断当前是否是模块首页
  const isModuleIndex = useMemo(() => {
    if (curRoute?.state?.index) return true;
    return false;
  }, [curRoute]);
  const moduleIndexUrl = useMemo(() => {
    if (!curRoute) return '';
    if (isModuleIndex) return curRoute?.path;
    return RouteList.find((cv) => cv.state?.module === curRoute.state?.module && cv.state?.index)
      ?.path;
  }, [curRoute, isModuleIndex]);
  return {
    route: curRoute,
    isModuleIndex,
    moduleIndexUrl,
  };
}

export function replaceDynamicParams(url: string, params: Record<string, string | undefined>) {
  // 匹配链接中的动态参数，例如 ':clusterId'
  const dynamicParamPattern = /:[^/]+/g;

  // 查找链接中的所有动态参数
  const dynamicParams = url.match(dynamicParamPattern);

  // 遍历动态参数，替换为对应的值
  let replacedUrl = url;
  if (dynamicParams) {
    dynamicParams.forEach((param) => {
      const paramName = param.substr(1); // 去掉冒号前缀
      const paramValue = params[paramName];
      replacedUrl = replacedUrl.replace(param, paramValue || '');
    });
  }

  return replacedUrl;
}
