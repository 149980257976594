import { useContext } from 'react';
import { ConfirmCallback, ConfirmContext } from '../contexts/cluster-group/confirm-context';

// ----------------------------------------------------------------------
export default function useConfirm() {
  const context = useContext(ConfirmContext);
  const {
    open,
    title,
    content,
    variant,
    onConfirm,
    setOpen,
    setTitle,
    setContent,
    setOnConfirm,
    setVariant,
  } = context;

  const openConfirmDialog = (
    t: string,
    f: ConfirmCallback,
    c?: string,
    curVariant?: 'error' | 'info'
  ) => {
    setTitle(t);
    setOnConfirm(() => f);
    if (c) {
      setContent(c);
    }
    if (curVariant) {
      setVariant(curVariant);
    }
    setOpen(true);
  };

  const closeConfirmDialog = () => {
    setOpen(false);
    setTitle('');
    setContent('');
    setVariant('info');
    setOnConfirm(() => () => {});
  };

  const confirm = async () => {
    await onConfirm();
    closeConfirmDialog();
  };

  return {
    openConfirmDialog,
    closeConfirmDialog,
    open,
    title,
    content,
    variant,
    confirm,
  };
}
