import Button from '@mui/material/Button';
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';

// router
import { paths } from 'src/routes/paths';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import clusetGroupPng from 'src/images/cluset-group.png';
import { useLocales } from '../../../../locales';
import Image from '../../../../components/image';
import { useClusterGroupContext } from '../../../../contexts/cluster-group/use-cluster-group-context';

export default function NoClusterView() {
  const { t } = useLocales();
  const { selectedClusterGroupId } = useClusterGroupContext();

  return (
    <Stack direction="row" alignItems="center" style={{ height: 'calc(100vh - 128px)' }}>
      <Stack
        style={{ position: 'relative', width: '830px', margin: 'auto' }}
        direction="row"
        justifyContent="start"
        alignItems="center"
      >
        <Stack sx={{ zIndex: 1, width: '500px' }}>
          <Typography variant="subtitle2" color="text.primary">
            {t('cluster_manage.no_available_cluster')}
          </Typography>
          <Typography variant="h3" color="text.primary" sx={{ mt: 1.5, mb: 1 }}>
            {t('cluster_manage.access_cluster')}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 3.75 }}>
            {t('cluster_manage.automq_cluster_description')}
          </Typography>

          <Button
            component={RouterLink}
            color="primary"
            href={paths.dashboard.cluster.integrate(selectedClusterGroupId!)}
            variant="contained"
            startIcon={<Iconify icon="mdi:add" />}
            sx={{ width: '254px' }}
          >
            {t('cluster_manage.access_cluster_broker')}
          </Button>
        </Stack>
        <Image sx={{ position: 'absolute', right: 0 }} src={clusetGroupPng} maxWidth={480} />
      </Stack>
    </Stack>
  );
}
