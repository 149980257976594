// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import { useRouteInfo } from 'src/hooks/use-paths';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
//
import { CustomBreadcrumbsProps } from './types';
import LinkItem from './link-item';

// ----------------------------------------------------------------------

export default function CustomBreadcrumbs({
  links,
  action,
  heading,
  btn,
  description,
  moreLink,
  activeLast,
  sx,
  overrideHelpLink,
  ...other
}: CustomBreadcrumbsProps & {
  overrideHelpLink?: string;
}) {
  const lastLink = links.length ? links[links.length - 1].name : '';
  const { t } = useTranslation();
  const { route } = useRouteInfo();
  const helpLink = useMemo(() => route?.state?.helpLinkKey, [route]);
  return (
    <Box sx={{ ...sx }}>
      {!!links.length && (
        <Breadcrumbs sx={{ mb: 2 }} separator={<Separator />} {...other}>
          {links.map((link) => (
            <LinkItem
              key={link.name || ''}
              link={link}
              activeLast={activeLast}
              disabled={link.name === lastLink}
              onClick={(e) => {
                link.onClick?.(e);
              }}
            />
          ))}
        </Breadcrumbs>
      )}
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          {/* HEADING */}
          <Stack direction="row" alignItems="center">
            <Box sx={{ flexGrow: 1 }}>
              {heading && (
                <Typography variant="h5">
                  {heading}
                  {/* {btn && <span> {btn} </span>} */}
                  {(overrideHelpLink || helpLink) && (
                    <Link
                      sx={{ ml: 2 }}
                      variant="caption"
                      href={t(overrideHelpLink ?? helpLink)}
                      target="_blank"
                    >
                      {t('information')}
                    </Link>
                  )}
                </Typography>
              )}
              {description && (
                <Typography variant="caption" color="text.secondary">
                  {description}
                </Typography>
              )}
            </Box>

            {action && <Box sx={{ minWidth: '30%', textAlign: 'right' }}> {action} </Box>}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return (
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: '50%',
        bgcolor: 'text.disabled',
      }}
    />
  );
}
