import { Theme } from '@mui/material/styles';
import { LinkProps } from '@mui/material/Link';

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const;

// NEW VARIANT
declare module '@mui/material/Link' {
  interface LinkPropsVariantOverrides {
    soft: true;
  }
}

// ----------------------------------------------------------------------
export function link(theme: Theme) {
  const lightMode = theme.palette.mode === 'light';

  const rootStyles = (ownerState: LinkProps) => {
    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.color === color && {
        color: theme.palette[color][lightMode ? 'dark' : 'light'],
      }),
    }));

    return [...colorStyle];
  };

  return {
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: LinkProps }) => rootStyles(ownerState),
      },
    },
  };
}
