// @mui
import Typography from '@mui/material/Typography';
// assets
import { Stack } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { HEADER } from 'src/layouts/config-layout';
import help from '../../images/help.png';

// ----------------------------------------------------------------------

export default function HelpDocument() {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{ position: 'relative' }}
      height={`calc(100vh - ${HEADER.H_DESKTOP}px)`}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Stack gap={2} sx={{ zIndex: 1, width: '548px' }}>
        <Alert severity="warning" sx={{ mt: 2.5 }}>
          {t('help_web_site')}
        </Alert>
        <Typography variant="body1" color="text.primary" textAlign="center" whiteSpace="nowrap">
          {t('help_link')}
          <Link color="primary">https://automq.com/docs/xxx/xxxx</Link>
        </Typography>
        <Typography variant="body2" color="text.secondary" textAlign="center">
          {t('help_qrcode')}
        </Typography>
        <Box
          component="img"
          alt="empty content"
          src={help}
          bgcolor="grey.200"
          sx={{
            mx: 'auto',
            mt: 3,
            width: 1,
            maxWidth: 240,
            p: 2.5,
          }}
        />
      </Stack>
    </Stack>
  );
}
