import axios, { AxiosRequestConfig } from 'axios';
import { enqueueSnackbar } from 'notistack';
// config
import { HANDLE_RESP_CODE, HOST_API } from 'src/config-global';
import { ErrorResponse } from 'src/types/base';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  baseURL: HOST_API,
});
const codesMap = new Map<AxiosRequestConfig, string[]>();
axiosInstance.interceptors.request.use((config) => {
  const codes = config.headers[HANDLE_RESP_CODE];
  if (codes && codes.length > 0) {
    codesMap.set(config, codes);
    delete config.headers[HANDLE_RESP_CODE];
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => {
    codesMap.delete(res.config);
    return res;
  },
  (error) => {
    const codes = codesMap.get(error.config);
    codesMap.delete(error.config);
    let message: string | undefined;
    let code = '';
    const { response } = error;
    if (response) {
      const { data } = response as { data: ErrorResponse };
      message = data?.error?.message;
      code = data?.error?.code;
      if (!message) {
        message = data?.error?.code;
      }
    }
    if (!message) {
      message = '服务端错误';
    }
    if (error.response.status >= 400 && error.response.status < 600) {
      // 显示错误
      if (!codes || !codes.includes(code)) {
        enqueueSnackbar(message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: null,
        });
      }
    }
    return Promise.reject(response?.data?.error);
  }
);

const cookieKey = 'copilot-tempCId';

axiosInstance.interceptors.request.use(
  (config) => {
    if (Cookies.get(cookieKey)) {
      config.headers.tempCId = Cookies.get(cookieKey);
    }
    return config;
  },
  (error) =>
    // 处理请求错误
    Promise.reject(error)
);
export default axiosInstance;

export const endpoints = {
  auth: {
    login: '/auth/login',
    register: '/auth/register',
  },
  clusterGroup: {
    list: '/groups',
    add: '/groups',
    delete: '/groups/',
  },
};

/**
 * get请求
 * @param {*} url     请求地址
 * @param {*} params
 */
export async function get<T>(url: string, params?: object, config: AxiosRequestConfig = {}) {
  return (await axiosInstance.get(url, { ...config, params })).data as T;
}

/**
 * post 请求
 * @param {*} url     请求地址
 * @param {*} data   参数
 * @param {*} config 配置
 */
export async function post<T>(url: string, data: object, config?: AxiosRequestConfig) {
  return (await (
    await axiosInstance.post(url, data, config)
  ).data) as T;
}

export async function postForm<T>(url: string, data: object, config?: AxiosRequestConfig) {
  return (await (
    await axiosInstance.postForm(url, data, config)
  ).data) as T;
}

/**
 * put 请求
 * @param {*} url     请求地址
 * @param {*} data
 */
export async function put<T>(url: string, data: object, config?: AxiosRequestConfig) {
  return (await axiosInstance.put(url, data, config)).data as T;
}

/**
 * delete 请求
 * @param {*} url
 */
export async function del<T>(url: string, params: object = {}, config: object = {}) {
  return (
    await axiosInstance.delete(url, {
      ...config,
      params,
    })
  ).data as T;
}

/**
 * patch 请求
 * @param {*} url  请求地址
 */
export async function patch<T>(url: string, data?: object, config?: AxiosRequestConfig) {
  return (await axiosInstance.patch(url, data, config)).data as T;
}
