export const healthyStatus = [
  {
    value: 'P0',
    label: 'P0',
    color: 'rgba(0, 184, 217, 1)',
  },
  {
    value: 'P1',
    label: 'P1',
    color: 'rgba(255, 171, 0, 1))',
  },
  {
    value: 'P2',
    label: 'P2',
    color: 'rgba(255, 86, 48, 1)',
  },
];

export const brokerStatus = [
  {
    value: 0,
    label: 'cluster_manage.enum_broker_status.normal',
  },
  {
    value: 1,
    label: 'cluster_manage.enum_broker_status.abnormal',
  },
  {
    value: 2,
    label: 'cluster_manage.enum_broker_status.unknown',
  },
];
