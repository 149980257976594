import { healthyStatus, brokerStatus } from 'src/constants/cluster';
import converterTemplate from 'src/utils/converter-template';

export const convertBrokerStatus = converterTemplate<number>(brokerStatus);

export const convertBrokerStatus2Color = (status: number) => {
  switch (status) {
    case 0:
      return 'success';
    case 1:
      return 'error';
    default:
      return 'default';
  }
};

export const convertBrokerId = (id: number, t: (key: string) => string) => {
  if (id === 0) {
    return t('cluster_manage.enum_broker_id.master');
  }
  return t('cluster_manage.enum_broker_id.slave');
};

export const convertHealthyStatus = converterTemplate<number>(healthyStatus);
