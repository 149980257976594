import { Paper, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { usePalette } from 'src/hooks/use-palette';
import { useResponsive } from 'src/hooks/use-responsive';
import { NAV } from 'src/layouts/config-layout';

export default function MainPaper({
  children,
  justifyContent = 'flex-end',
}: PropsWithChildren<{ justifyContent?: string }>) {
  const lgUp = useResponsive('up', 'lg');
  const { paletteColor } = usePalette();
  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: lgUp ? NAV.W_VERTICAL : 0, right: 0, zIndex: 99 }}
      elevation={3}
    >
      <Stack
        direction="row"
        justifyContent={justifyContent}
        alignItems="center"
        sx={{
          borderTop: '1px solid rgba(145, 158, 171, 0.24)',
          background: paletteColor.grey[100],
          height: '56px',
          width: '100%',
          px: 3,
        }}
        gap={1.5}
      >
        {children}
      </Stack>
    </Paper>
  );
}
