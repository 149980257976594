/**
 * 风险等级
 */
export const riskLevels = [
  {
    value: 'P0',
    label: 'risk_deal_manage.enum_risk_level.p0',
    color: '#F30000',
    colorAttr: [
      { offset: 0, color: '#F30000' },
      // { offset: 0.2, color: '#FF8282' },
      { offset: 0.5, color: '#FF8282' },
      // { offset: 0.6, color: '#FF8282' },
      { offset: 1, color: '#F30000' },
    ],
  },
  {
    value: 'P1',
    label: 'risk_deal_manage.enum_risk_level.p1',
    color: '#FF5630',
    colorAttr: [
      { offset: 0, color: '#FF5630' },
      // { offset: 0.2, color: '#FFAC82' },
      { offset: 0.5, color: '#FFAC82' },
      // { offset: 0.6, color: '#FFAC82' },
      { offset: 1, color: '#FF5630' },
    ],
  },
  {
    value: 'P2',
    label: 'risk_deal_manage.enum_risk_level.p2',
    color: '#FFAB00',
    colorAttr: [
      { offset: 0, color: '#FFAB00' },
      // { offset: 0.2, color: '#FFD666' },
      { offset: 0.5, color: '#FFD666' },
      // { offset: 0.6, color: '#FFD666' },
      { offset: 1, color: '#FFAB00' },
    ],
  },
  {
    value: 'HEALTH',
    label: 'risk_deal_manage.enum_risk_level.health',
    color: '#36B37E',
    colorAttr: [
      { offset: 0, color: '#36B37E' },
      // { offset: 0.2, color: '#86E8AB' },
      { offset: 0.5, color: '#86E8AB' },
      // { offset: 0.6, color: '#86E8AB' },
      { offset: 1, color: '#36B37E' },
    ],
  },
];
/**
 * 通知告警状态
 */
export const notifyStatus = [
  {
    value: 0,
    label: '未配置',
  },
  {
    value: 1,
    label: '已禁用',
  },
  {
    value: 2,
    label: '已启用',
  },
];

/**
 * 风险状态
 */
export const riskStatus = [
  {
    value: 'PENDING',
    label: 'risk_deal_manage.enum_risk_status.pending',
  },
  {
    value: 'PROCESSED',
    label: 'risk_deal_manage.enum_risk_status.processed',
  },
];
