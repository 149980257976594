import i18n, { PostProcessorModule } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { keyMap } from 'src/i18n-viewer';
import { localStorageGetItem } from 'src/utils/storage-available';
import { defaultLang } from './config-lang';
import translationCn from './langs/cn.json';
import translationEn from './langs/en.json';
// ----------------------------------------------------------------------
const lng = localStorageGetItem('i18nextLng', defaultLang.value);
const debugPlugin: PostProcessorModule = {
  type: 'postProcessor',
  name: 'textDetailProcessor',
  process: (value, key, options, translator) => {
    if (localStorage.getItem('showTextKey') === 'open') {
      return `!!<[${keyMap.get(key[0])}]>${value}`;
    }
    return value;
  },
};

i18n
  .use(debugPlugin)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    postProcess: process.env.REACT_APP_ENV === 'dev' ? ['textDetailProcessor'] : [],
    resources: {
      en: { translations: translationEn },
      cn: { translations: translationCn },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
