// routes
// components
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';

import { useParams } from 'react-router';
import { getClusterInfo } from 'src/api/cluster-api';
import { useEffect, useState } from 'react';
import { ICluster } from 'src/types/cluster';
import useGroupName from 'src/hooks/use-group-name';
import { Stack } from '@mui/system';
import { useRoutePath } from 'src/hooks/use-paths';
import ClusterNewEditForm from './components/cluster-new-edit-form';
import { useLocales } from '../../../locales';

export default function UserCreateView() {
  const { t } = useLocales();
  const { groupId } = useGroupName();
  const { clusterId } = useParams();
  const [currentCluster, setCurrentCluster] = useState<ICluster | undefined>(undefined);
  const RoutePath = useRoutePath();
  useEffect(() => {
    if (clusterId) {
      getClusterInfo(groupId!, clusterId).then((cluster) => {
        setCurrentCluster(cluster);
      });
    }
  }, [groupId, clusterId]);
  return (
    <Stack>
      <CustomBreadcrumbs
        heading={clusterId ? t('cluster_manage.edit_cluster') : t('cluster_manage.access_cluster')}
        description={t('cluster_manage.rocketmq_copilot_description')}
        links={[
          {
            name: t('system_administration'),
            href: '',
          },
          {
            name: t('cluster_management'),
            href: RoutePath.clusterList,
          },
          {
            name: t('cluster_manage.access_cluster'),
            href: '',
          },
        ]}
        sx={{ mt: 4, mb: 3 }}
      />
      <ClusterNewEditForm currentCluster={currentCluster} />
    </Stack>
  );
}
