// @mui
import Box, { BoxProps } from '@mui/material/Box';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
//
import { HEADER } from '../../config-layout';
import ActivateAlert from '../../../sections/system/components/activate-alert';

// ----------------------------------------------------------------------

export default function Main({ children, sx, ...other }: BoxProps) {
  const lgUp = useResponsive('up', 'lg');

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_DESKTOP}px`,
        overflow: 'auto',
        px: 0,
        ...(lgUp && {
          py: `${HEADER.H_DESKTOP}px`,
        }),
        ...sx,
      }}
      {...other}
    >
      <ActivateAlert />
      {children}
    </Box>
  );
}
