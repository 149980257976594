// @mui
import Box from '@mui/material/Box';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
//
import { styled } from '@mui/material/styles';
import ActivateAlert from 'src/sections/system/components/activate-alert';
import { Outlet } from 'react-router-dom';
import Header from '../header';
import NavVertical from '../nav-vertical';
import Main from './main';
import { ConfirmDialog } from '../../../sections/system/components/confirm-dialog';
import { ConfirmDialogProvider } from '../../../contexts/cluster-group/confirm-context';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const nav = useBoolean();

  return (
    <StyledRoot>
      <Header onOpenNav={nav.onTrue} />
      <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
      <Main sx={{ overflow: 'auto' }}>
        <Box
          sx={{
            minWidth: '1160px',
            overflow: 'auto',
            flex: 1,
            px: 5,
            pb: 5,
          }}
        >
          <ActivateAlert />
          <ConfirmDialogProvider>
            <Outlet />
            <ConfirmDialog />
          </ConfirmDialogProvider>
        </Box>
      </Main>
    </StyledRoot>
  );
}
