import { useMemo, useState } from 'react';
import { palette } from 'src/theme/palette';

export function usePalette() {
  const [theme] = useState<'light' | 'dark'>('light');
  const paletteColor = useMemo(() => palette(theme), [theme]);
  return {
    paletteColor,
  };
}
