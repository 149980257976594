import { useEffect, useMemo, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
//
import Card from '@mui/material/Card';
import { CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import { useTranslation } from 'react-i18next';
import { usePalette } from 'src/hooks/use-palette';
import { Wrapper } from 'src/components/wrapper';
import { HEADER, NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { useClusterGroupContext } from '../../contexts/cluster-group/use-cluster-group-context';
import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

const MenuIcon = () => (
  <SvgIcon
    sx={{
      width: '20',
      height: '20',
      viewBox: '0 0 20 20',
      fill: 'none',
      xmlns: 'http://www.w3.org/2000/svg',
    }}
  >
    <g id="icons/ic_menu">
      <g id="shape">
        <path
          d="M4.16667 10C4.16667 10.4602 3.79357 10.8333 3.33333 10.8333C2.8731 10.8333 2.5 10.4602 2.5 10C2.5 9.53976 2.8731 9.16667 3.33333 9.16667C3.79357 9.16667 4.16667 9.53976 4.16667 10Z"
          fill="#5046E2"
        />
        <path
          d="M5.83333 9.95C5.83333 9.51738 6.18404 9.16667 6.61667 9.16667H16.7167C17.1493 9.16667 17.5 9.51738 17.5 9.95V10.05C17.5 10.4826 17.1493 10.8333 16.7167 10.8333H6.61667C6.18404 10.8333 5.83333 10.4826 5.83333 10.05V9.95Z"
          fill="#5046E2"
        />
        <path
          d="M2.5 14.1167C2.5 13.684 2.85071 13.3333 3.28333 13.3333H16.7167C17.1493 13.3333 17.5 13.684 17.5 14.1167V14.2167C17.5 14.6493 17.1493 15 16.7167 15H3.28333C2.85071 15 2.5 14.6493 2.5 14.2167V14.1167Z"
          fill="#5046E2"
        />
        <path
          d="M2.5 5.78333C2.5 5.35071 2.85071 5 3.28333 5H16.7167C17.1493 5 17.5 5.35071 17.5 5.78333V5.88333C17.5 6.31596 17.1493 6.66667 16.7167 6.66667H3.28333C2.85071 6.66667 2.5 6.31596 2.5 5.88333V5.78333Z"
          fill="#5046E2"
        />
      </g>
    </g>
  </SvgIcon>
);

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { user } = useMockedUser();

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const { clusterGroups, selectedClusterGroupId, setSelectedClusterGroupId } =
    useClusterGroupContext();
  const { t } = useTranslation();
  const handleMenuItemClick = (groupId: string) => {
    handleClose();
    setSelectedClusterGroupId(groupId);
  };
  const selectedClusterGroup = useMemo(
    () => clusterGroups.find((cv) => cv.groupId === selectedClusterGroupId),
    [clusterGroups, selectedClusterGroupId]
  );

  const [open, setOpen] = useState(false);
  const [openEl, setOpenEl] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(true);
    setOpenEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenEl(null);
  };
  const { paletteColor } = usePalette();

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Button
          onClick={handleOpen}
          variant="text"
          sx={{ pl: 3.3, py: 3, justifyContent: 'start' }}
          style={{ height: 'auto', width: '100%' }}
        >
          <Stack sx={{ width: '100%' }} gap={1}>
            <Stack
              color="text.primary"
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                color="text.primary"
                variant="h5"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {selectedClusterGroup?.name ?? '--'}
              </Typography>

              {open && <Iconify width="12px" minWidth="12px" icon="icon-park-solid:up-one" />}
              {!open && <Iconify width="12px" minWidth="12px" icon="icon-park-solid:down-one" />}
            </Stack>
            <Typography
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              variant="caption"
              color="text.disabled"
              sx={{ textAlign: 'left' }}
            >
              NS:{selectedClusterGroup ? selectedClusterGroup.namesrvList.join(';') : '--'}
            </Typography>
          </Stack>
        </Button>
        <Wrapper show={open}>
          <Popover
            open={open}
            anchorEl={openEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            slotProps={{
              paper: {
                sx: {
                  p: 0,
                  mt: 1.5,
                  ml: 0.75,
                  width: 400,
                },
              },
            }}
          >
            <Box sx={{ my: 0, px: 0, width: '100%' }}>
              <Card>
                <CardContent sx={{ px: 1, pt: 1.5, pb: 0, maxHeight: '200px', overflow: 'auto' }}>
                  {clusterGroups.map((row) => {
                    const { groupId, name, namesrvList } = row;
                    return (
                      <Button
                        key={groupId}
                        className="popoverBtn"
                        sx={{
                          width: '100%',
                          alignItems: 'start',
                          px: 2,
                          py: 0.75,
                          background:
                            selectedClusterGroupId === groupId
                              ? paletteColor.action.selected
                              : undefined,
                        }}
                        onClick={() => handleMenuItemClick(groupId)}
                      >
                        <Stack sx={{ width: '100%', textAlign: 'start' }}>
                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="body2"
                              color="text.primary"
                              whiteSpace="nowrap"
                              overflow="hidden"
                              textOverflow="ellipsis"
                            >
                              {`${name}`}
                            </Typography>
                            <Wrapper show={selectedClusterGroupId === groupId}>
                              <Iconify icon="mdi:success" />
                            </Wrapper>
                          </Stack>
                          <Typography
                            variant="caption"
                            color="text.disabled"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                          >
                            NS:{namesrvList}
                          </Typography>
                        </Stack>
                      </Button>
                    );
                  })}
                </CardContent>
                <Stack sx={{ px: 1, pb: 1.5 }}>
                  <Button
                    component={RouterLink}
                    href={paths.dashboard.clusterGroupManager}
                    color="primary"
                    sx={{
                      width: '100%',
                      justifyContent: 'start',
                      alignItems: 'center',
                      px: 2,
                      py: 0.75,
                      height: '46px',
                    }}
                    startIcon={<MenuIcon />}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {t('cluster_group_management')}
                  </Button>
                  <Button
                    component={RouterLink}
                    href={paths.dashboard.addClusterGroupManager}
                    color="primary"
                    sx={{
                      width: '100%',
                      justifyContent: 'start',
                      alignItems: 'center',
                      px: 2,
                      py: 0.75,
                      height: '46px',
                    }}
                    startIcon={<Iconify icon="mingcute:add-line" />}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    {t('cluster_group.add.label')}
                  </Button>
                </Stack>
              </Card>
            </Box>
          </Popover>
        </Wrapper>

        {/* <FormControl variant="standard">
              <Select
                value={selectedClusterGroupId}
                onChange={handleSelectChange}
                style={{ height: '50px' }}
              >
                {clusterGroups.map((row) => {
                  const { groupId, name, namesrvList } = row;
                  return (
                    <MenuItem
                      value={groupId}
                      key={groupId}
                      onClick={() => handleMenuItemClick(groupId)}
                    >{`${name} NS(${namesrvList})`}</MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
      </Box>
      <Divider />
      <NavSectionVertical
        data={navData}
        config={{
          currentRole: user?.role || 'admin',
        }}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      {lgUp ? (
        <Stack
          bgcolor="common.white"
          sx={{
            py: `${HEADER.H_DESKTOP}px`,
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            zIndex: 2,
            borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
