type Options = {
  valueProp?: string;
  keyProp?: string;
  defaultValue?: any;
};

export default function converterTemplate<V>(
  list: any[],
  { valueProp = 'label', keyProp = 'value', defaultValue = '未知' }: Options = {}
) {
  return (value: V, t?: (key: string) => string) => {
    const ret = list.filter((item) => item.value === value)[0][valueProp];
    if (ret === undefined || ret === null) {
      return defaultValue;
    }
    return t ? t(ret) : ret;
  };
}
