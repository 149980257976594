import { Helmet } from 'react-helmet-async';
// sections
import HelpDocument from '../sections/help/help-document-view';

// ----------------------------------------------------------------------

export default function HelpPage() {
  return (
    <>
      <Helmet>
        <title> Help Document!</title>
      </Helmet>

      <HelpDocument />
    </>
  );
}
