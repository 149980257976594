import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { ClusterGroupProvider } from 'src/contexts/cluster-group/cluster-group-context';
import { LicenseStatusProvider } from 'src/contexts/license-status/license-status-context';
import ClusterIntegrateView from 'src/sections/cluster-manage/cluster/cluster-integrate-view';
import SystemInspectRuleEdit from 'src/pages/dashboard/system-inspect/system-inspect-rule-edit';
import SystemInspectRiskPanel from 'src/pages/dashboard/system-inspect/system-inspect-risk-panel';
import DashboardLayoutNoSideBar from 'src/layouts/dashboard/layout-nosidebar';
import HelpPage from 'src/pages/help';
import SplashScreenGuard from '../guard/splash-screen-guard';

// ----------------------------------------------------------------------

const RootView = lazy(() => import('src/pages/dashboard/root'));
const ClusterGroupManagerView = lazy(() => import('src/pages/dashboard/cluster-group-manager'));
const ClusterManagerView = lazy(() => import('src/pages/dashboard/cluster-manager'));
const AddClusterGroup = lazy(
  () => import('src/sections/cluster-manage/cluster-group/add-cluster-group-view')
);
// 集群详情
const ClusterGroupDetail = lazy(
  () => import('src/sections/cluster-manage/cluster-group/cluster-group-detail-view')
);
// 接入集群详情
const ClusterDetail = lazy(
  () => import('src/sections/cluster-manage/cluster/cluster-integrate-detail-view')
);
const SystemInspectRule = lazy(
  () => import('src/pages/dashboard/system-inspect/system-inspect-rule')
);

const SystemInspectEvent = lazy(
  () => import('src/pages/dashboard/system-inspect/system-inspect-event')
);

const SystemInspectClusterRiskDeal = lazy(
  () => import('src/pages/dashboard/system-inspect/system-inspect-cluster-risk-deal')
);

const SystemInspectRiskDetail = lazy(
  () => import('src/pages/dashboard/system-inspect/system-inspect-cluster-risk-detail')
);
const SystemInspectRiskWarnEdit = lazy(
  () => import('src/pages/dashboard/system-inspect/system-inspect-cluster-risk-warn-edit')
);

const SystemInspectRiskAlertRuleCreate = lazy(
  () => import('src/pages/dashboard/system-inspect/create-risk-alert-rule-page')
);
// expert
const HistoryDiagnosis = lazy(
  () => import('src/pages/dashboard/expert-diagnosis/history-diagnosis')
);
const DiagnosisDetail = lazy(() => import('src/pages/dashboard/expert-diagnosis/diagnosis-detail'));
const DiagnosisLoading = lazy(
  () => import('src/pages/dashboard/expert-diagnosis/diagnosis-loading')
);
const CommitDiagnosis = lazy(() => import('src/pages/dashboard/expert-diagnosis/commit-diagnosis'));

// system
const UserManagement = lazy(() => import('src/pages/dashboard/system/user-management'));
const SubscriptionService = lazy(() => import('src/pages/dashboard/system/subscription-service'));
const Management = lazy(() => import('src/pages/dashboard/system/management'));

// governance
const SLOMetrics = lazy(() => import('src/pages/dashboard/cluster-governance/slo-metrics'));
const SLODetail = lazy(() => import('src/pages/dashboard/cluster-governance/slo-detail'));
const SLOCreate = lazy(() => import('src/pages/dashboard/cluster-governance/slo-create'));

const ClusterGroupEmpty = lazy(() => import('src/pages/cluster-group-empty'));

// sli
const SLI = lazy(() => import('src/pages/dashboard/sli/sli'));

// dashboard
const DashboardManager = lazy(
  () => import('src/pages/dashboard/dashboard-manager/dashboard-manager')
);

// 容量规划
const CapacityPlanning = lazy(
  () => import('src/pages/dashboard/capacity-planning/capacity-planning')
);
// 容量规划详情页
const CapacityPlanningDetail = lazy(
  () => import('src/pages/dashboard/capacity-planning/capacity-detail')
);
// 创建容量规划页
const CapacityPlanningCreate = lazy(
  () => import('src/pages/dashboard/capacity-planning/capacity-create')
);
export const dashboardRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <ClusterGroupProvider>
          <LicenseStatusProvider>
            <SplashScreenGuard>
              <DashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </DashboardLayout>
            </SplashScreenGuard>
          </LicenseStatusProvider>
        </ClusterGroupProvider>
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <RootView />,
        index: true,
      },
      {
        path: 'cluster-group-manager',
        element: <ClusterGroupManagerView />,
        state: {
          helpLinkKey: 'page_help_link.cluster_group_list',
          module: 'clusterGroup',
          index: true,
        },
      },
      {
        path: 'cluster-group-manager/add',
        element: <AddClusterGroup />,
        state: { helpLinkKey: 'page_help_link.clusterGroupIntegrate', module: 'clusterGroup' },
      },
      {
        path: 'cluster-group-manager/:groupId/detail',
        element: <ClusterGroupDetail />,
        state: { helpLinkKey: 'page_help_link.cluster_group_detail', module: 'clusterGroup' },
      },
      // system-inspect
      {
        path: 'group/system-inspect/dashboard',
        element: <ClusterGroupEmpty />,
        state: { helpLinkKey: 'page_help_link.dashboard' },
      },
      {
        path: 'group/system-inspect/rule',
        element: <ClusterGroupEmpty />,
        state: { helpLinkKey: 'page_help_link.ruleList' },
      },
      {
        path: 'group/system-inspect/risk/panel',
        element: <ClusterGroupEmpty />,
        state: { helpLinkKey: 'page_help_link.riskPanel' },
      },
      {
        path: 'group/expert',
        element: <ClusterGroupEmpty />,
        state: { helpLinkKey: 'page_help_link.diagnosis_list' },
      },
      {
        path: 'group/slo',
        element: <ClusterGroupEmpty />,
        state: { helpLinkKey: 'page_help_link.SLOList' },
      },
      {
        path: 'group/performance',
        element: <ClusterGroupEmpty />,
        // state: { helpLinkKey: 'page_help_link.cluster_group_list'},
      },
      // { path: 'group/cluster-manager', element: <ClusterGroupEmpty /> },
      {
        path: 'group/:groupId',
        children: [
          {
            path: 'cluster-manager',
            element: <ClusterManagerView />,
            state: { helpLinkKey: 'page_help_link.clusterList', module: 'cluster', index: true },
          },
          {
            path: 'cluster-manager/integrate',
            element: <ClusterIntegrateView />,
            state: { helpLinkKey: 'page_help_link.cluster_integrate', module: 'cluster' },
          },
          {
            path: 'cluster-manager/:clusterId/edit',
            element: <ClusterIntegrateView />,
            // state: { helpLinkKey: 'page_help_link.cluster_group_list',
          },
          {
            path: 'cluster-manager/:clusterId/detail',
            element: <ClusterDetail />,
            state: { helpLinkKey: 'page_help_link.clusterDetail', module: 'cluster' },
          },
          {
            path: 'system-inspect/dashboard',
            element: <DashboardManager />,
            state: { helpLinkKey: 'page_help_link.dashboard', module: 'dashboard', index: true },
          },
          {
            path: 'system-inspect/rule',
            element: <SystemInspectRule />,
            state: { helpLinkKey: 'page_help_link.ruleList', module: 'rule', index: true },
          },
          {
            path: 'system-inspect/event',
            element: <SystemInspectEvent />,
            // state: { helpLinkKey: 'page_help_link.cluster_group_list'},
          },
          {
            path: 'system-inspect/rule/:inspectId/edit',
            element: <SystemInspectRuleEdit />,
            state: { helpLinkKey: 'page_help_link.ruleEdit', module: 'rule' },
          },
          {
            path: 'system-inspect/risk/panel',
            element: <SystemInspectRiskPanel />,
            state: { helpLinkKey: 'page_help_link.riskPanel', module: 'risk', index: true },
          },
          {
            path: 'system-inspect/risk/panel/:clusterId',
            element: <SystemInspectClusterRiskDeal />,
            state: { helpLinkKey: 'page_help_link.riskList', module: 'risk' },
          },
          {
            path: 'system-inspect/risk/panel/:clusterId/detail',
            element: <SystemInspectRiskDetail />,
            state: { helpLinkKey: 'page_help_link.riskDetail', module: 'risk' },
          },
          {
            path: 'system-inspect/risk/panel/:clusterId/alert-rule/create',
            element: <SystemInspectRiskAlertRuleCreate />,
            state: { helpLinkKey: 'page_help_link.riskAlertRuleCreate', module: 'risk' },
          },
          {
            path: 'system-inspect/risk/panel/:clusterId/edit',
            element: <SystemInspectRiskWarnEdit />,
            state: { helpLinkKey: 'page_help_link.riskEdit', module: 'risk' },
          },
          {
            path: 'expert-diagnosis/panel',
            element: <HistoryDiagnosis />,
            state: {
              helpLinkKey: 'page_help_link.diagnosis_list',
              module: 'diagnosis',
              index: true,
            },
          },
          {
            path: 'expert-diagnosis/panel/:diagnosisId',
            element: <DiagnosisDetail />,
            state: { helpLinkKey: 'page_help_link.diagnosis_detail', module: 'diagnosis' },
          },
          {
            path: 'expert-diagnosis/panel/:diagnosisId/loading',
            element: <DiagnosisLoading />,
            state: { module: 'diagnosis' },
          },
          {
            path: 'expert-diagnosis/panel/add',
            element: <CommitDiagnosis />,
            state: { helpLinkKey: 'page_help_link.diagnosis_create', module: 'diagnosis' },
          },
        ],
      },
      // governance
      {
        path: 'group/:groupId/slo',
        element: <SLOMetrics />,
        state: { helpLinkKey: 'page_help_link.SLOList', module: 'slo', index: true },
      },
      {
        path: 'group/:groupId/slo/:sloId/detail',
        element: <SLODetail />,
        state: { helpLinkKey: 'page_help_link.SLODetail', module: 'slo' },
      },
      {
        path: 'group/:groupId/slo/create',
        element: <SLOCreate />,
        state: { helpLinkKey: 'page_help_link.SLOCreate', module: 'slo' },
      },
      {
        path: '/slo',
        element: <ClusterGroupEmpty />,
        // state: { helpLinkKey: 'page_help_link.SLOList' },
      },
      // sli
      {
        path: 'group/:groupId/sli',
        element: <SLI />,
        state: { helpLinkKey: 'page_help_link.SLIPanel', module: 'sli', index: true },
      },

      // 容量规划 capacity
      {
        path: 'group/:groupId/capacity',
        element: <CapacityPlanning />,
        state: { helpLinkKey: 'page_help_link.CapacityPlanning', module: 'capacity', index: true },
      },
      // 容量规划 详情页
      {
        path: 'group/:groupId/capacity/:capacityId/detail',
        element: <CapacityPlanningDetail />,
        state: { helpLinkKey: 'page_help_link.CapacityPlanning', module: 'capacity' },
      },
      // 容量规划 创建表单页
      {
        path: 'group/:groupId/capacity/create',
        element: <CapacityPlanningCreate />,
        state: { helpLinkKey: 'page_help_link.CapacityPlanning', module: 'capacity' },
      },

      // system
      {
        path: 'sub-service',
        element: <SubscriptionService />,
        state: { helpLinkKey: 'page_help_link.cluster_group_list' },
      },
      { path: 'users', element: <UserManagement /> },
      {
        path: 'management',
        element: <Management />,
        state: { helpLinkKey: 'page_help_link.systemManage', module: 'system', index: true },
      },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <LicenseStatusProvider>
          <DashboardLayoutNoSideBar>
            <Outlet />
          </DashboardLayoutNoSideBar>
        </LicenseStatusProvider>
      </AuthGuard>
    ),
    children: [{ path: 'help', element: <HelpPage /> }],
  },
];

interface RouteItem {
  path: string;
  element: React.ReactNode;
  children?: RouteItem[];
  state?: Record<string, any>;
}
const getPath = (route: RouteItem, prePath: string) => {
  const arr: Pick<RouteItem, 'path' | 'state'>[] = [];
  arr.push({ path: `${prePath}/${route.path}`, state: route.state });
  if (route.children) {
    route.children.forEach((item) => {
      arr.push(...getPath(item, prePath + route.path));
    });
  }
  return arr;
};

export const RouteList: Pick<RouteItem, 'path' | 'state'>[] = (
  dashboardRoutes as RouteItem[]
).reduce(
  (pre, cur) => {
    pre.push(...getPath(cur, ''));
    return pre;
  },
  [] as Pick<RouteItem, 'path' | 'state'>[]
);
