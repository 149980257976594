import { Button, DialogActions, DialogContent, IconButton, MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { RHFSelect } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface Props {
  open: boolean;
  onClose: () => void;
  onEdit: (data: any) => Promise<void>;
}

export function ClusterEditDialog({ open, onClose, onEdit }: Props) {
  const { t } = useTranslation();
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required(t('cluster.integrate.topic_for_sli_required')),
    TopicName: Yup.string().required(t('cluster.integrate.topic_for_sli_required')),
  });
  const defaultValues = {
    name: '',
    TopicName: '',
  };
  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      sx={{ '& .MuiDialog-paper': { width: 480 } }}
    >
      <DialogTitle sx={{ pb: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          {t('inspection_manage.add_white_list')}
          <IconButton onClick={onClose}>
            <Iconify icon="mingcute:close-line" width={18} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ px: 3, py: 2, borderRadius: 0 }}>
        <FormProvider methods={methods}>
          <RHFSelect sx={{ my: 3 }} name="vpc" label="集群名称" size="medium">
            <MenuItem value="1">option</MenuItem>
          </RHFSelect>
          <RHFSelect sx={{ mb: 3 }} name="vpc" label="Topic 名称" size="medium">
            <MenuItem value="1">option</MenuItem>
          </RHFSelect>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button sx={{ height: '36px' }} variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          sx={{ height: '36px' }}
          variant="outlined"
          onClick={methods.handleSubmit(onEdit)}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
